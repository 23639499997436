import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Container, TitlePageContainer, ButtonsContainer } from './styles';

import Input from '~/components/Input';
import Select from '~/components/Select';

import api from '~/services/api';

const { confirm } = Modal;

function NewPushGame({ setAddingNewGame, matchId, getPushGames }) {
  const handleSubmit = useCallback(
    async (values, actions) => {
      await new Promise(resolve => {
        confirm({
          title: 'Deseja realmente criar esse jogo',
          icon: <ExclamationCircleOutlined />,
          content:
            'Esse jogo aparecerá imediatamente para os usuários da plataforma Lance Futebol',
          cancelText: 'Cancelar',
          okText: 'Criar jogo',
          onOk() {
            resolve(true);
          },
          onCancel() {
            actions.setSubmitting(false);
          },
        });
      });
      const body = {
        _game: matchId,
        ...values,
      };
      try {
        await api.post(`/api/push-game`, body);
        toast.success('Jogo criado com sucesso!');
        getPushGames();
        setAddingNewGame(false);
      } catch (error) {
        console.log(error);
        toast.error('Aconteceu um erro inesperado ao criar o jogo!');
      }
      return null;
    },
    [getPushGames, matchId, setAddingNewGame]
  );

  const eneableSubmitButton = useCallback(({ errors }) => {
    if (Object.entries(errors).length > 0) {
      return false;
    }

    return true;
  }, []);

  return (
    <Container>
      <TitlePageContainer>
        <h5>Novo jogo</h5>
        <Button onClick={() => setAddingNewGame(false)} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Formik
        initialValues={{
          question: '',
          price: '',
          amount: '',
          buttonType: 0,
        }}
        validationSchema={Yup.object().shape({
          question: Yup.string().required('O pergunta é obrigatória'),
          price: Yup.number().required('O valor do jogo é obrigatório'),
          amount: Yup.number().required('O valor do prêmio é obrigatório'),
          buttonType: Yup.number().required('O tipo do botão é obrigatório'),
        })}
        validateOnMount
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <FastField name="question">
              {({ field, meta }) => {
                return (
                  <Input
                    {...field}
                    label="Pergunta"
                    placeholder="Digite a pergunta"
                    identifier="question"
                    type="text"
                    error={meta?.touched && meta?.error && meta?.error}
                  />
                );
              }}
            </FastField>
            <FastField name="price">
              {({ field, meta }) => {
                return (
                  <Input
                    {...field}
                    label="Valor do jogo"
                    placeholder="Digite o valor do jogo"
                    identifier="price"
                    type="text"
                    error={meta?.touched && meta?.error && meta?.error}
                    addonBefore="LF$"
                  />
                );
              }}
            </FastField>
            <FastField name="amount">
              {({ field, meta }) => {
                return (
                  <Input
                    {...field}
                    label="Valor do prêmio"
                    placeholder="Digite o valor do prêmio"
                    identifier="amount"
                    type="text"
                    error={meta?.touched && meta?.error && meta?.error}
                    addonBefore="LF$"
                  />
                );
              }}
            </FastField>
            <FastField name="buttonType">
              {({ field, meta, form }) => {
                return (
                  <Select
                    {...field}
                    label="Tipo de botão"
                    identifier="buttonType"
                    placeholder="Selecione o tipo de botão"
                    options={[
                      { label: 'Botões SIM e NÃO', value: 0 },
                      { label: 'Somente botão SIM', value: 1 },
                      { label: 'Somente botão NÃO', value: 2 },
                    ]}
                    error={meta?.touched && meta?.error}
                    onChange={value => {
                      form.setFieldValue('buttonType', value);
                    }}
                  />
                );
              }}
            </FastField>
            <ButtonsContainer>
              <Button
                htmlType="submit"
                disabled={
                  formikProps.isSubmitting || !eneableSubmitButton(formikProps)
                }
                type="primary"
              >
                {!formikProps.isSubmitting ? 'Criar jogo' : 'Carregando...'}
              </Button>
            </ButtonsContainer>
          </form>
        )}
      </Formik>
      {/* <Content>
        <h6>Informações do usuário</h6>
        
      </Content> */}
    </Container>
  );
}

export default NewPushGame;
