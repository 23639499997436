import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Container,
  TitlePageContainer,
  Content,
  SettingItem,
  AdminIcon,
  AdvertisementIcon,
} from './styles';

function Settings() {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <Container>
      <TitlePageContainer>
        <h4>Configurações</h4>
      </TitlePageContainer>
      <Content>
        <SettingItem onClick={() => history.push(`${url}/administrators`)}>
          <div>
            <AdminIcon />
            <h5>Administradores</h5>
          </div>
          <p>Gerencie os administradores da plataforma Lance Futebol</p>
        </SettingItem>
        <SettingItem onClick={() => history.push(`${url}/advertisement`)}>
          <div>
            <AdvertisementIcon />
            <h5>Publicidade</h5>
          </div>
          <p>Gerencie a publicidade da plataforma Lance Futebol</p>
        </SettingItem>
      </Content>
    </Container>
  );
}

export default Settings;
