import { MdDashboard, MdPeople, MdSettings } from 'react-icons/md';
import { IoMdCash, IoMdFootball, IoMdTrophy } from 'react-icons/io';
import { FiFlag } from 'react-icons/fi';
import { BsShieldFill } from 'react-icons/bs';

import { getStyledIcon } from './MenuItem/styles';

const ADMIN_MENU = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    selected: false,
    icon: getStyledIcon(MdDashboard),
  },
  {
    name: 'users',
    label: 'Usuários',
    selected: false,
    icon: getStyledIcon(MdPeople),
  },
  {
    name: 'sponsors',
    label: 'Patrocinadores',
    selected: false,
    icon: getStyledIcon(IoMdCash),
  },
  {
    name: 'teams',
    label: 'Times',
    selected: false,
    icon: getStyledIcon(BsShieldFill),
  },
  {
    name: 'championships',
    label: 'Campeonatos',
    selected: false,
    icon: getStyledIcon(IoMdTrophy),
  },
  {
    name: 'matchs',
    label: 'Partidas',
    selected: false,
    icon: getStyledIcon(IoMdFootball),
  },
  {
    name: 'chats_reports',
    label: 'Denúncias dos Chats',
    selected: false,
    icon: getStyledIcon(FiFlag),
  },
  {
    name: 'athletes_of_the_week',
    label: 'Artilheiros da semana',
    selected: false,
    icon: getStyledIcon(BsShieldFill),
  },
  {
    name: 'settings',
    label: 'Configurações',
    selected: false,
    icon: getStyledIcon(MdSettings),
  },
];

const TEAM_MENU = [
  {
    name: 'my_team',
    label: 'Meu time',
    selected: false,
    icon: getStyledIcon(BsShieldFill),
  },
  {
    name: 'my_matches',
    label: 'Partidas',
    selected: false,
    icon: getStyledIcon(IoMdFootball),
  },
];

export function getMenu(roles) {
  if (roles.includes('admin')) return ADMIN_MENU;
  if (roles.includes('team')) return TEAM_MENU;

  return {};
}
