import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import Input from '~/components/Input';
import api from '~/services/api';
import useBeforeUnload from '~/hooks/useBeforeUnload';
import useMergeRefs from '~/hooks/useMergeRefs';
import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

function EditUserInformations({ team, setTeam, parentRouteMatch }) {
  const history = useHistory();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef(null);

  const [focusedField, setFocusedField] = useState(null);
  const [haveChanges, setHaveChanges] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    currentValues => {
      if (_.isEqual(team, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [haveChanges, team]
  );

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { _id } = team;

      if (!_id) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse time, atualize a página e tente novamente!'
        );
      }

      const body = {
        name: values?.name,
        initials: values?.initials?.toUpperCase(),
      };

      try {
        await api.put(`/api/manager-team`, body);

        toast.success('Dados atualizados com sucesso!');
        setTeam({ ...team, ...body });
        actions.setSubmitting(false);
        unblockPage();

        history.push(parentRouteMatch.url);
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao atualizar o avatar do usuário!'
        );
      }
      return null;
    },
    [setTeam, team, unblockPage, history, parentRouteMatch.url]
  );

  const eneableSubmitButton = useCallback(
    ({ touched, errors, values }) => {
      if (
        _.isEqual(team, values) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [team]
  );

  const handleFormikRef = node => {
    if (node !== null) {
      checkIfHaveChanges(node.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Dados do time</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={{
            name: team?.name || '',
            initials: team?.initials || '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('O nome do time é obrigatório'),
            initials: Yup.string().required(
              'As iniciais do time são obrigatórias'
            ),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="name">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome"
                      placeholder="Digite o nome do time"
                      identifier="name"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onFocus={() => setFocusedField('name')}
                    />
                  );
                }}
              </FastField>
              <FastField name="initials">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome"
                      placeholder="Digite as iniciais do time"
                      identifier="initials"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onFocus={() => setFocusedField('name')}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditUserInformations;
