import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import Switch from '~/components/Switch';
import api from '~/services/api';
import useBeforeUnload from '~/hooks/useBeforeUnload';
import useMergeRefs from '~/hooks/useMergeRefs';

function EditPermissions({ admin, setAdmin, parentRouteMatch }) {
  const history = useHistory();
  const params = useParams();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef(null);

  const [haveChanges, setHaveChanges] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    currentValues => {
      if (_.isEqual(admin?.permissions, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [admin.permissions, haveChanges]
  );

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { id } = params;

      if (!id) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse administrador, atualize a página e tente novamente!'
        );
      }

      const body = {
        subRoles: Object.entries(values).reduce((total, permission) => {
          const [key, value] = permission;
          if (value) {
            return [...total, key];
          }

          return total;
        }, []),
      };

      try {
        await api.put(`/api/admin/${id}`, body);

        toast.success('Dados atualizados com sucesso!');
        setAdmin({ ...admin, permissions: values });
        actions.setSubmitting(false);
        unblockPage();

        history.push(parentRouteMatch.url);
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao atualizar os dados do administrador!'
        );
      }
      return null;
    },
    [admin, history, params, parentRouteMatch.url, setAdmin, unblockPage]
  );

  const eneableSubmitButton = useCallback(
    ({ values }) => {
      if (_.isEqual(admin?.permissions, values)) {
        return false;
      }

      return true;
    },
    [admin.permissions]
  );

  const handleFormikRef = node => {
    if (node !== null) {
      checkIfHaveChanges(node.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Permissões</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={{
            manager_user: admin?.permissions?.manager_user || false,
            manager_admin: admin?.permissions?.manager_admin || false,
            manager_sponsor: admin?.permissions?.manager_sponsor || false,
            manager_team: admin?.permissions?.manager_team || false,
          }}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="manager_user">
                {({ field, form }) => {
                  return (
                    <Switch
                      label="Gestão de usuários"
                      checked={field.value}
                      onChange={checked =>
                        form.setFieldValue(field.name, checked)
                      }
                      size="small"
                    />
                  );
                }}
              </FastField>
              <FastField name="manager_admin">
                {({ field, form }) => {
                  return (
                    <Switch
                      label="Gestão de administradores"
                      checked={field.value}
                      onChange={checked =>
                        form.setFieldValue(field.name, checked)
                      }
                      size="small"
                    />
                  );
                }}
              </FastField>
              <FastField name="manager_sponsor">
                {({ field, form }) => {
                  return (
                    <Switch
                      label="Gestão de patrocinadores"
                      checked={field.value}
                      onChange={checked =>
                        form.setFieldValue(field.name, checked)
                      }
                      size="small"
                    />
                  );
                }}
              </FastField>
              <FastField name="manager_team">
                {({ field, form }) => {
                  return (
                    <Switch
                      label="Gestão de times"
                      checked={field.value}
                      onChange={checked =>
                        form.setFieldValue(field.name, checked)
                      }
                      size="small"
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditPermissions;
