import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import { Button, Spin, Modal } from 'antd';
import { toast } from 'react-toastify';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdEdit, MdBlock } from 'react-icons/md';
// import { HiOutlineExclamationCircle } from 'react-icons/hi';
// import { RiImageAddFill } from 'react-icons/ri';

import {
  Container,
  Header,
  Content,
  ImageWrapper,
  UserInformationsHeader,
  UserNotFoundContainer,
  ActionButtonAvatar,
} from './styles';

import Breadcrums from '~/components/Breadcrumbs';
import CustomAntButton from '~/components/CustomAntButton';

import api from '~/services/api';

import Env from '~/config/Environment';

import NotFoundImage from '~/assets/NotFound.svg';
import DefaultAvatar from '~/assets/DefaultAvatar.svg';

import Details from './Details';
import UpdateAvatar from './UpdateAvatar';
import EditUserInformations from './EditUserInformations';
import EditPersonalData from './EditPersonalData';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { confirm } = Modal;

function User({ match }) {
  const params = useParams();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);
  // const [confirmRemoveAvatar, setConfirmRemoveAvatar] = useState(false);
  // const [loadingRemoveUserAvatar, setLoadingRemoveUserAvatar] = useState(false);

  useEffect(() => {
    async function getUser(_id) {
      if (!_id) {
        return console.log('Erro!!');
      }

      try {
        const { data } = await api.get(`/api/user-admin/${_id}`);

        const isUser = !!data?.roles.find(role => role === 'user');

        if (!isUser) {
          toast.warn('Esse identificador não pertence a um usuário');
          return history.push('/users');
        }

        setUser({
          userInformations: {
            username: data?.username || 'Não informado',
            email: data?.email || 'Não informado',
          },
          personalData: {
            name: data?.name || 'Não informado',
            gender: data?.gender || 'Não informado',
            docNumber: data?.docNumber || 'Não informado',
            phone: data?.phones[0] || 'Não informado',
          },
          avatar: data?.photo?.filename || null,
          status: data?.status,
        });
        setLoadingUser(false);
      } catch (error) {
        setLoadingUser(false);
      }
      return '';
    }

    getUser(params?.id);
  }, [history, params.id]);

  const handleDisableUser = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse usuário, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente desabilitar esse usuário?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele não terá mais acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Desativar usuário',
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve();
          },
          onCancel() {
            reject(new Error('CANCELED_USER_DELETE_ACTION'));
          },
        });
      });

      await api.delete(`/api/user-admin/${_id}`);

      toast.success('Usuário desativado com sucesso');
      setUser({ ...user, status: 0 });
      // history.push('/users');
    } catch (error) {
      if (error.message === 'CANCELED_USER_DELETE_ACTION') {
        return null;
      }
    }

    return null;
  }, [params.id, user]);

  const handleEneableUser = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse usuário, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente habilitar esse usuário?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele poderá ter acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Ativar usuário',
          okButtonProps: {
            // danger: true,
          },
          onOk() {
            resolve();
          },
          onCancel() {
            reject(new Error('CANCELED_USER_ENEABLE_ACTION'));
          },
        });
      });

      await api.put(`/api/user-admin/${_id}`, {
        status: 1,
      });

      toast.success('Usuário ativo com sucesso');
      setUser({ ...user, status: 1 });
    } catch (error) {
      if (error.message === 'CANCELED_USER_ENEABLE_ACTION') {
        return null;
      }
    }

    return null;
  }, [params.id, user]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (
      page.includes('/edit_user_informations') ||
      page.includes('/edit_personal_data')
    ) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  const changeStatusUserButton = useMemo(() => {
    if (!user) return null;

    const { status } = user;

    if (status === 1) {
      return (
        <Button onClick={handleDisableUser} type="text" danger>
          Desativar usuário
        </Button>
      );
    }
    if (status === 0) {
      return (
        <CustomAntButton onClick={handleEneableUser} type="text" textprimary>
          Ativar usuário
        </CustomAntButton>
      );
    }
    return null;
  }, [handleDisableUser, handleEneableUser, user]);

  const mobileChangeStatusUserButton = useMemo(() => {
    if (!user) return null;

    const { status } = user;

    if (status === 1) {
      return (
        <Button
          onClick={handleDisableUser}
          type="text"
          danger
          icon={<MdBlock size={21} />}
        />
      );
    }
    if (status === 0) {
      return (
        <CustomAntButton
          onClick={handleEneableUser}
          type="text"
          textprimary
          icon={<IoMdCheckmarkCircle size={21} />}
        />
      );
    }
    return null;
  }, [handleDisableUser, handleEneableUser, user]);

  if (loadingUser) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  if (!user) {
    return (
      <Container>
        <UserNotFoundContainer>
          <Breadcrums match={routeMatch} />
          <div>
            <img src={NotFoundImage} alt="Not Found" />
            <h6>Esse usuário não foi encontrado</h6>
          </div>
        </UserNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Breadcrums match={match} />
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main') {
                setAvatarModalVisible(true);
              }
            }}
            clickEneabled={currentLocation === 'main'}
          >
            <img
              src={
                user?.avatar
                  ? Env.IMAGE_SERVER_URL + user?.avatar
                  : DefaultAvatar
              }
              alt={user?.personalData?.name || 'Avatar'}
            />
          </ImageWrapper>
          <UserInformationsHeader>
            <div>
              <div>
                <h5>{user?.personalData?.name}</h5>
                {user.status === 0 && <span>Inativo</span>}
              </div>
              <p>{user?.userInformations?.username}</p>
              <p>{user?.userInformations?.email}</p>
            </div>
            {currentLocation === 'main' && changeStatusUserButton}
          </UserInformationsHeader>
          {currentLocation === 'main' && mobileChangeStatusUserButton}
        </div>
      </Header>
      <Content>
        <Switch>
          <Route exact path={routeMatch.path}>
            <Details user={user} routeMatch={routeMatch} />
          </Route>
          <Route path={`${routeMatch.path}/update_avatar`}>
            <UpdateAvatar
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_user_informations`}>
            <EditUserInformations
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_personal_data`}>
            <EditPersonalData
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route>
        </Switch>
      </Content>
      <Modal
        title="Avatar do usuário"
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        // cancelButtonProps={{
        //   disabled: loadingRemoveUserAvatar,
        // }}
        cancelText="Voltar"
      >
        <Link
          to={`${routeMatch.url}/update_avatar`}
          onClick={() => setAvatarModalVisible(false)}
        >
          <ActionButtonAvatar>
            <MdEdit size={24} />
            <span>Atualizar avatar</span>
          </ActionButtonAvatar>
        </Link>
      </Modal>
    </Container>
  );
}

export default User;
