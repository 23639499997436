import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getRoutes } from '~/routes/routes';

function Breadcrumbs(props) {
  const me = useSelector(state => state.user.profile);
  const routesList = getRoutes(me?.roles);

  const crumbs = routesList
    // Get all routes that contain the current one.
    .filter(
      routeDefinition =>
        props.match.path.includes(routeDefinition.path) &&
        !!routeDefinition.isPrivate
    )
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    .map(({ path, name }) => ({
      path: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce(
            (currentPath, param) =>
              currentPath.replace(`:${param}`, props.match.params[param]),
            path
          )
        : path,
      name,
    }));

  const preRoutes = crumbs;
  const currentRoute = crumbs.pop();
  return (
    <Breadcrumb>
      {preRoutes.map(({ path, name }) => (
        <Breadcrumb.Item key={`crumb_${path}`}>
          <Link to={path}>{name}</Link>
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item>{currentRoute?.name}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default Breadcrumbs;
