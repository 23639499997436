import React from 'react';
import { Button } from 'antd';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, SessionHeader, SessionItems } from './styles';

import * as formatters from '~/utils/formatters';

function Details({ user, routeMatch }) {
  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>Informações do usuário</h6>
          <Link to={`${routeMatch.url}/edit_user_informations`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome de usuário</small>
            <p>{user?.userInformations?.username}</p>
          </li>
          <li>
            <small>Email</small>
            <p>{user?.userInformations?.email}</p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>Dados pessoais</h6>
          <Link to={`${routeMatch.url}/edit_personal_data`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome</small>
            <p>{user?.personalData?.name}</p>
          </li>
          <li>
            <small>Sexo</small>
            <p>{formatters.formatGender(user?.personalData?.gender)}</p>
          </li>
          <li>
            <small>CPF</small>
            <p>{formatters.formatCpf(user?.personalData?.docNumber)}</p>
          </li>
          <li>
            <small>Telefone</small>
            <p>{formatters.formatPhone(user?.personalData?.phone)}</p>
          </li>
        </SessionItems>
      </div>
    </Container>
  );
}

export default Details;
