import React from 'react';
import { Input as InputAnt, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './styles';

function Input({
  label,
  identifier,
  error,
  showloading,
  actionButton,
  ...rest
}) {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: '#009420' }} spin />
  );
  return (
    <Container error={error}>
      <label htmlFor={identifier}>{label}</label>
      <div>
        <InputAnt id={identifier} bordered {...rest} />
        {showloading && <Spin indicator={antIcon} />}
        {actionButton}
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
}

export default Input;
