import React from 'react';
import { Button } from 'antd';

import { ButtonsContainer } from '../styles';

import AvatarCropper from '~/components/AvatarCropper';

function Image({ formik, nextStep }) {
  function eneableNextStepButton({ values }) {
    if (values?.image?.file) {
      return true;
    }

    return false;
  }

  return (
    <>
      <AvatarCropper
        croppedAvatar={formik?.values?.image}
        setCroppedAvatar={({ file, previewUrl }) => {
          formik.setFieldValue('image.file', file);
          formik.setFieldValue('image.previewUrl', previewUrl);
        }}
        required
        selectImageButtonLabel="Selecione uma imagem para o anúncio"
        aspect={21 / 9}
      />
      <ButtonsContainer>
        {/* <Button onClick={prevStep} type="default">
          Voltar
        </Button> */}
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Image;
