import React from 'react';
import { Button } from 'antd';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import Env from '~/config/Environment';
import DefaultAvatar from '~/assets/DefaultAvatar.svg';

import { ButtonsContainer } from '../styles';
import { SessionContainer, SessionItems, TeamPlayer } from './styles';

function getPlayerPositionLabel(playerPosition) {
  switch (playerPosition) {
    case 'G':
      return 'Goleiro';
    case 'D':
      return 'Defesa';
    case 'M':
      return 'Meio campo';
    case 'A':
      return 'Ataque';
    default:
      return playerPosition;
  }
}

function Confirm({ formik, prevStep, onSubmit }) {
  return (
    <>
      <SessionContainer>
        <h6>Informações da cartela</h6>
        <SessionItems>
          <li>
            <small>Nome da cartela</small>
            <p>{formik.values.cardInformations.name}</p>
          </li>
          <li>
            <small>Preço do jogo</small>
            <p>{formik.values.cardInformations.price}</p>
          </li>
          <li>
            <small>Data e hora inicial</small>
            <p>
              {format(
                formik.values.cardInformations.startDate.toDate(),
                "cccc, dd/MM/yyyy 'às' HH:mm",
                {
                  locale: ptLocale,
                }
              )}
            </p>
          </li>
          <li>
            <small>Data e hora final</small>
            <p>
              {format(
                formik.values.cardInformations.endDate.toDate(),
                "cccc, dd/MM/yyyy 'às' HH:mm",
                {
                  locale: ptLocale,
                }
              )}
            </p>
          </li>
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Jogadores</h6>
        <div>
          {formik.values.athletes.map(player => (
            <TeamPlayer key={player._id}>
              <div>
                <img
                  src={
                    player?.photo
                      ? Env.IMAGE_SERVER_URL + player.photo
                      : DefaultAvatar
                  }
                  alt={player.name}
                />
              </div>
              <div>
                <strong>{player.name}</strong>
                <small>
                  Camisa - {player.number} ·{' '}
                  {getPlayerPositionLabel(player.position)}
                </small>
                <div>
                  <img src={player.team.image} alt={player.team.name} />
                  <p>{player.team.name}</p>
                </div>
              </div>
            </TeamPlayer>
          ))}
        </div>
      </SessionContainer>
      <SessionContainer>
        <h6>Banner</h6>
        {formik.values.banner?.previewUrl ? (
          <img src={formik.values.banner?.previewUrl} alt="Banner" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Confirm;
