import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Spin, Modal } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import Env from '~/config/Environment';

import {
  Container,
  TitlePageContainer,
  AthleteCardDetailContainer,
  AthleteCardDetailMainContent,
  AthleteCardDetailPlayersContent,
  AthleteCardFinishedSwithContainer,
} from './styles';

import Breadcrumbs from '~/components/Breadcrumbs';

import api from '~/services/api';

import AthleteCardPlayer from './AthleteCardPlayer';
import CustomAntButton from '~/components/CustomAntButton';

const loadingIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { format, parseISO } = dateFns;
const { confirm } = Modal;

const cardTypesLabels = {
  new: 'Disponível para jogar',
  running: 'Em execução',
  finished: 'Finalizada',
};

function UpdateResults() {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { params } = useRouteMatch();

  const [card, setCard] = useState(null);
  const [loadingCard, setLoadingCard] = useState(true);
  const [cardForm, setCardForm] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCardDetails = useCallback(async () => {
    try {
      const { data } = await api.get(`/api/athlete-card/${params.id}`);

      setCard({
        ...data.doc,
        athletes: data.doc.athletes.map(athlete => ({
          ...athlete,
          status: athlete.status || false,
        })),
        gameCount: data.cardCount,
        amount: data.amount,
      });
      setCardForm({
        athletes: data.doc.athletes.map(athlete => ({
          ...athlete,
          status: athlete.status || false,
        })),
        finished: data.doc.finished,
      });
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da cartela. Recarregue a página e tente novamente!'
      );
    }
    setLoadingCard(false);
    return '';
  }, [params.id]);

  useEffect(() => {
    getCardDetails();
  }, [getCardDetails]);

  const isFinishButtonEnabled = useMemo(() => {
    const haveAllGamesBeenFinished = cardForm?.athletes.every(
      athlete => athlete.status
    );
    const isCurrentDateAfterCardEndDate = dateFns.isAfter(
      new Date(),
      dateFns.parseISO(card?.endDate)
    );
    const isCardFinished = card?.finished;

    return (
      haveAllGamesBeenFinished &&
      isCurrentDateAfterCardEndDate &&
      !isCardFinished
    );
  }, [card, cardForm]);

  const handleFinishCard = useCallback(async () => {
    await new Promise(resolve => {
      confirm({
        title: 'Deseja realmente finalizar essa cartela?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível',
        cancelText: 'Cancelar',
        okText: 'Finalizar cartela',
        onOk() {
          resolve(true);
        },
      });
    });

    const body = {
      finished: true,
    };

    setIsSubmitting(true);
    try {
      await api.put(`/api/end-athlete/${params.id}`, body);
      toast.success('Cartela finalizada com sucesso!');
      history.push(`/athletes_of_the_week`);
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Aconteceu um erro inesperado!');
    }
  });

  if (loadingCard) {
    return (
      <Container>
        <Breadcrumbs match={routeMatch} />
        <TitlePageContainer>
          <h4>Atualizar resultados</h4>
          <Button
            onClick={() => history.push(`/athletes_of_the_week`)}
            danger
            type="text"
          >
            Cancelar
          </Button>
        </TitlePageContainer>
        <Spin indicator={loadingIcon} />
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumbs match={routeMatch} />
      <TitlePageContainer>
        <h4>Atualizar resultados</h4>
        <Button
          onClick={() => history.push(`/athletes_of_the_week`)}
          danger
          type="text"
        >
          Voltar
        </Button>
      </TitlePageContainer>
      <AthleteCardDetailContainer key={card._id}>
        <AthleteCardDetailMainContent
          bg={card.banner && Env.IMAGE_SERVER_URL + card.banner}
          cardType={card.cardType}
        >
          <div>
            <h6>{card.name}</h6>
          </div>
          <small>{cardTypesLabels[card.cardType]}</small>
          <small>
            <b>Valor do jogo: </b>LF$ {card.price}
          </small>
          <small>
            <b>Data de início da cartela: </b>
            {format(parseISO(card.startDate), "cccc, dd/MM/yyyy 'às' HH:mm", {
              locale: ptLocale,
            })}
          </small>
          <small>
            <b>Data de fim da cartela: </b>
            {format(parseISO(card.endDate), "cccc, dd/MM/yyyy 'às' HH:mm", {
              locale: ptLocale,
            })}
          </small>
          <small>
            <b>Cartela finalizada: </b>
            {card.finished ? 'Sim' : 'Não'}
          </small>
        </AthleteCardDetailMainContent>
        <AthleteCardDetailPlayersContent>
          {cardForm.athletes ? (
            cardForm.athletes.map(athlete => (
              <AthleteCardPlayer
                athlete={athlete}
                cardForm={cardForm}
                setCardForm={setCardForm}
                disabled={isSubmitting || card?.finished}
              />
            ))
          ) : (
            <p>Nenhum artilheiro encontrado nessa cartela</p>
          )}
          {isFinishButtonEnabled && (
            <AthleteCardFinishedSwithContainer>
              <CustomAntButton
                onClick={handleFinishCard}
                type="primary"
                loading={isSubmitting}
              >
                Finalizar cartela
              </CustomAntButton>
            </AthleteCardFinishedSwithContainer>
          )}
        </AthleteCardDetailPlayersContent>
      </AthleteCardDetailContainer>
    </Container>
  );
}

export default UpdateResults;
