import styled from 'styled-components';

export const SessionContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;

  > img {
    width: 104px;
    margin-top: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }

  > p {
    margin-top: 16px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const TeamPlayer = styled.div`
  border: 0;
  background: ${props => props.theme.colors.light['light-1']};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0;
  text-align: start;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  }

  > div:nth-child(1) {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.dark['dark+4']};
    background: ${props => props.theme.colors.light['light-2']};
    transition: 0.2s opacity;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  div:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      overflow: inherit;
      text-overflow: inherit;
      color: ${props => props.theme.colors.dark['dark+2']};

      /* &:last-child {
        color: ${props => props.theme.colors.primary.primary0};
      } */
    }

    div {
      margin-top: 8px;
      display: flex;
      align-items: center;

      img {
        height: 18px;
        margin-right: 8px;
      }

      p {
        color: ${props => props.theme.colors.dark['dark+2']};
      }
    }
  }
`;
