import axios from 'axios';
import { store } from '~/store';

import { signOutSuccess } from '~/store/modules/auth/actions';

import Environment from '../config/Environment';

const api = axios.create({
  baseURL: Environment.API_URL,
  // baseURL: 'http://192.168.13.102:8000',
});

api.interceptors.request.use(config =>
  process.env.NODE_ENV === 'development'
    ? new Promise(resolve => setTimeout(() => resolve(config), 500))
    : config
);

api.interceptors.response.use(
  response => response,
  error => {
    /* Invalid token */
    if (error.response.status === 401) {
      return store.dispatch(signOutSuccess());
    }
    return Promise.reject(error);
  }
);

export default api;
