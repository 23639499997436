import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Checkbox, Pagination, Spin, Modal } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import {
  FiChevronUp,
  FiChevronDown,
  FiCheckCircle,
  FiFlag,
} from 'react-icons/fi';

import api from '~/services/api';

import CustomAntButton from '~/components/CustomAntButton';
import Switch from '~/components/Switch';

import Message from '../Message';

import {
  Container,
  ToogleViewerButton,
  ReportedMessagesList,
  ReportedMessageContainer,
  ReportedMessageMainContent,
  ReportedMessageDetailsContent,
  LoadingReportedMessageDetailsContainer,
  LoadingAndNotFoundContainer,
} from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { confirm } = Modal;

function ChatAthletesOfTheWeek() {
  const [showSolvedReports, setShowSolvedReports] = useState(false);

  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messagesPagination, setMessagesPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    limit: 10,
  });

  const [solvedMessages, setSolvedMessages] = useState([]);
  const [loadingSolvedMessages, setLoadingSolvedMessages] = useState(false);
  const [solvedMessagesPagination, setSolvedMessagesPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    limit: 10,
  });

  const [submittingReport, setSubmittingReport] = useState('');

  const getMessages = useCallback(
    async (page = 1) => {
      setLoadingMessages(true);
      try {
        const { data } = await api.get(`/api/denounce/chat/card-athlete`, {
          params: {
            page,
            limit: messagesPagination.limit,
          },
        });

        setMessages(
          data.docs.map(cardGame => ({
            ...cardGame,
            collapsedOnList: false,
            loadingDetails: false,
            loadedDetails: false,
          }))
        );
        setMessagesPagination(oldState => ({
          currentPage: data.page,
          totalPages: data.total,
          limit: oldState.limit,
        }));
        setLoadingMessages(false);
      } catch (error) {
        setLoadingMessages(false);
      }
      return '';
    },
    [messagesPagination.limit]
  );

  const getMessageDetailsAndDenounces = useCallback(async _messageId => {
    setMessages(oldMessages =>
      oldMessages.map(oldMessage => {
        if (oldMessage._id === _messageId) {
          return {
            ...oldMessage,
            collapsedOnList: true,
            loadingDetails: true,
          };
        }
        return { ...oldMessage, collapsedOnList: false };
      })
    );

    try {
      const { data } = await api.get(
        `/api/denounce/chat/${_messageId}/card-athlete`
      );

      setMessages(oldMessages =>
        oldMessages.map(oldMessage => {
          if (oldMessage._id === _messageId) {
            return {
              ...oldMessage,
              isSolved: data.doc.isSolved,
              denounces: data.denounces,
              loadingDetails: false,
              loadedDetails: true,
            };
          }
          return { ...oldMessage };
        })
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da mensagem denúnciada. Recarregue a página e tente novamente!'
      );
      setMessages(oldCards =>
        oldCards.map(oldCard => {
          if (oldCard._id === _messageId) {
            return {
              ...oldCard,
              loadingDetails: false,
            };
          }
          return { ...oldCard, collapsedOnList: false };
        })
      );
    }
    return '';
  }, []);

  const getSolvedMessages = useCallback(
    async (page = 1) => {
      setLoadingSolvedMessages(true);
      try {
        const { data } = await api.get(`/api/denounce/chat/card-athlete`, {
          params: {
            page,
            limit: messagesPagination.limit,
            isSolved: true,
          },
        });

        setSolvedMessages(
          data.docs.map(message => ({
            ...message,
            collapsedOnList: false,
            loadingDetails: false,
            loadedDetails: false,
          }))
        );
        setSolvedMessagesPagination(oldState => ({
          currentPage: data.page,
          totalPages: data.total,
          limit: oldState.limit,
        }));
        setLoadingSolvedMessages(false);
      } catch (error) {
        setLoadingSolvedMessages(false);
      }
      return '';
    },
    [messagesPagination.limit]
  );

  const getSolvedMessageDetailsAndDenounces = useCallback(async _messageId => {
    setSolvedMessages(oldMessages =>
      oldMessages.map(oldMessage => {
        if (oldMessage._id === _messageId) {
          return {
            ...oldMessage,
            collapsedOnList: true,
            loadingDetails: true,
          };
        }
        return { ...oldMessage, collapsedOnList: false };
      })
    );

    try {
      const { data } = await api.get(
        `/api/denounce/chat/${_messageId}/card-athlete`
      );

      setSolvedMessages(oldMessages =>
        oldMessages.map(oldMessage => {
          if (oldMessage._id === _messageId) {
            return {
              ...oldMessage,
              isSolved: data.doc.isSolved,
              denounces: data.denounces,
              loadingDetails: false,
              loadedDetails: true,
            };
          }
          return { ...oldMessage };
        })
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da mensagem denúnciada. Recarregue a página e tente novamente!'
      );
      setSolvedMessages(oldCards =>
        oldCards.map(oldCard => {
          if (oldCard._id === _messageId) {
            return {
              ...oldCard,
              loadingDetails: false,
            };
          }
          return { ...oldCard, collapsedOnList: false };
        })
      );
    }
    return '';
  }, []);

  const handleSubmit = useCallback(
    async _messageId => {
      await new Promise(resolve => {
        confirm({
          title: 'Deseja realmente os dados dessa denúncia?',
          icon: <ExclamationCircleOutlined />,
          content:
            'As possíveis alterações realizadas anteriormente serão substituídas',
          cancelText: 'Cancelar',
          okText: 'Solucionar',
          onOk() {
            resolve(true);
          },
        });
      });

      setSubmittingReport(_messageId);

      const message = !showSolvedReports
        ? messages.find(mess => mess._id === _messageId)
        : solvedMessages.find(mess => mess._id === _messageId);

      const body = {
        status: message.status,
        isSolved: message.isSolved,
      };

      try {
        await api.put(`/api/denounce/chat/${_messageId}/card-athlete`, body);

        toast.success('Dados da denúncia atualizados com sucesso!');

        setSubmittingReport('');
        setShowSolvedReports(false);
        getMessages();
      } catch (error) {
        setSubmittingReport('');
        toast.error(
          'Aconteceu um erro inesperado ao atualizar os dados da cartela!'
        );
      }
    },
    [getMessages, messages, showSolvedReports, solvedMessages]
  );

  useEffect(() => {
    if (!showSolvedReports) {
      getMessages();
    } else {
      getSolvedMessages();
    }
  }, [getMessages, getSolvedMessages, showSolvedReports]);

  const reportedMessagesListViewer = useMemo(() => {
    if (!showSolvedReports) {
      return (
        <>
          <ReportedMessagesList>
            {messages.length > 0 ? (
              messages.map(message => (
                <ReportedMessageContainer key={message._id}>
                  <ReportedMessageMainContent
                    onClick={() => {
                      if (!message.loadingDetails && !message.loadedDetails) {
                        getMessageDetailsAndDenounces(message._id);
                        return;
                      }
                      if (message.loadedDetails) {
                        setMessages(oldMessages =>
                          oldMessages.map(oldMessage => {
                            if (oldMessage._id === message._id) {
                              return {
                                ...oldMessage,
                                collapsedOnList: !oldMessage.collapsedOnList,
                              };
                            }
                            return { ...oldMessage, collapsedOnList: false };
                          })
                        );
                      }
                    }}
                    collapsedOnList={message.collapsedOnList}
                  >
                    <div>
                      <Message message={message} />
                      {!message.collapsedOnList ? (
                        <FiChevronDown size={24} />
                      ) : (
                        <FiChevronUp size={24} />
                      )}
                    </div>
                    <small>
                      <b>Cartela: </b>
                      {message._card.name}
                    </small>
                  </ReportedMessageMainContent>
                  {message.collapsedOnList && (
                    <>
                      {message.loadingDetails ? (
                        <ReportedMessageDetailsContent>
                          <LoadingReportedMessageDetailsContainer>
                            <Spin indicator={antIcon} />
                          </LoadingReportedMessageDetailsContainer>
                        </ReportedMessageDetailsContent>
                      ) : (
                        <ReportedMessageDetailsContent>
                          <div>
                            {message.denounces.map(denounce => (
                              <div key={denounce._id}>
                                <p>{denounce.count}</p>
                                <p>{denounce._id}</p>
                              </div>
                            ))}
                          </div>
                          <div>
                            <Checkbox
                              checked={!message.status}
                              onChange={e => {
                                setMessages(oldState => {
                                  return oldState.map(mess => {
                                    if (mess._id === message._id) {
                                      return {
                                        ...mess,
                                        status: !e.target.checked,
                                      };
                                    }
                                    return mess;
                                  });
                                });
                              }}
                            >
                              Excluir mensagem
                            </Checkbox>
                          </div>
                          <Switch
                            label="Denúncia solucionada"
                            checked={message.isSolved}
                            onChange={checked => {
                              setMessages(oldState => {
                                return oldState.map(mess => {
                                  if (mess._id === message._id) {
                                    return {
                                      ...mess,
                                      isSolved: checked,
                                    };
                                  }
                                  return mess;
                                });
                              });
                            }}
                            size="small"
                          />
                          <CustomAntButton
                            onClick={() => {
                              if (!submittingReport) {
                                handleSubmit(message._id);
                              }
                            }}
                            type="primary"
                            disabled={submittingReport}
                            loading={
                              submittingReport &&
                              submittingReport === message._id
                            }
                          >
                            Atualizar dados
                          </CustomAntButton>
                        </ReportedMessageDetailsContent>
                      )}
                    </>
                  )}
                </ReportedMessageContainer>
              ))
            ) : (
              <LoadingAndNotFoundContainer>
                <div>
                  <h6>
                    Não foram encontradas <span>mensagens denunciadas</span>
                  </h6>
                </div>
              </LoadingAndNotFoundContainer>
            )}
          </ReportedMessagesList>
          <Pagination
            current={messagesPagination.currentPage}
            onChange={page => {
              setMessagesPagination(oldState => ({
                ...oldState,
                currentPage: page,
              }));
              getMessages(page);
            }}
            total={messagesPagination.totalPages}
            pageSize={messagesPagination.limit}
          />
        </>
      );
    }

    return (
      <>
        <ReportedMessagesList>
          {solvedMessages.length > 0 ? (
            solvedMessages.map(message => (
              <ReportedMessageContainer key={message._id}>
                <ReportedMessageMainContent
                  onClick={() => {
                    if (!message.loadingDetails && !message.loadedDetails) {
                      getSolvedMessageDetailsAndDenounces(message._id);
                      return;
                    }
                    if (message.loadedDetails) {
                      setSolvedMessages(oldMessages =>
                        oldMessages.map(oldMessage => {
                          if (oldMessage._id === message._id) {
                            return {
                              ...oldMessage,
                              collapsedOnList: !oldMessage.collapsedOnList,
                            };
                          }
                          return { ...oldMessage, collapsedOnList: false };
                        })
                      );
                    }
                  }}
                  collapsedOnList={message.collapsedOnList}
                >
                  <div>
                    <Message message={message} />
                    {!message.collapsedOnList ? (
                      <FiChevronDown size={24} />
                    ) : (
                      <FiChevronUp size={24} />
                    )}
                  </div>
                  <small>
                    <b>Cartela: </b>
                    {message._card.name}
                  </small>
                </ReportedMessageMainContent>
                {message.collapsedOnList && (
                  <>
                    {message.loadingDetails ? (
                      <ReportedMessageDetailsContent>
                        <LoadingReportedMessageDetailsContainer>
                          <Spin indicator={antIcon} />
                        </LoadingReportedMessageDetailsContainer>
                      </ReportedMessageDetailsContent>
                    ) : (
                      <ReportedMessageDetailsContent>
                        <div>
                          {message.denounces.map(denounce => (
                            <div key={denounce._id}>
                              <p>{denounce.count}</p>
                              <p>{denounce._id}</p>
                            </div>
                          ))}
                        </div>
                        <div>
                          <Checkbox
                            checked={!message.status}
                            onChange={e => {
                              setSolvedMessages(oldState => {
                                return oldState.map(mess => {
                                  if (mess._id === message._id) {
                                    return {
                                      ...mess,
                                      status: !e.target.checked,
                                    };
                                  }
                                  return mess;
                                });
                              });
                            }}
                          >
                            Excluir mensagem
                          </Checkbox>
                        </div>
                        <Switch
                          label="Denúncia solucionada"
                          checked={message.isSolved}
                          onChange={checked => {
                            setSolvedMessages(oldState => {
                              return oldState.map(mess => {
                                if (mess._id === message._id) {
                                  return {
                                    ...mess,
                                    isSolved: checked,
                                  };
                                }
                                return mess;
                              });
                            });
                          }}
                          size="small"
                        />
                        <CustomAntButton
                          onClick={() => {
                            if (!submittingReport) {
                              handleSubmit(message._id);
                            }
                          }}
                          type="primary"
                          disabled={submittingReport}
                          loading={
                            submittingReport && submittingReport === message._id
                          }
                        >
                          Atualizar dados
                        </CustomAntButton>
                      </ReportedMessageDetailsContent>
                    )}
                  </>
                )}
              </ReportedMessageContainer>
            ))
          ) : (
            <LoadingAndNotFoundContainer>
              <div>
                <h6>
                  Não foram encontradas <span>mensagens solucionadas</span>
                </h6>
              </div>
            </LoadingAndNotFoundContainer>
          )}
        </ReportedMessagesList>
        <Pagination
          current={solvedMessagesPagination.currentPage}
          onChange={page => {
            setSolvedMessagesPagination(oldState => ({
              ...oldState,
              currentPage: page,
            }));
            getSolvedMessages(page);
          }}
          total={solvedMessagesPagination.totalPages}
          pageSize={solvedMessagesPagination.limit}
        />
      </>
    );
  }, [
    getMessageDetailsAndDenounces,
    getMessages,
    getSolvedMessageDetailsAndDenounces,
    getSolvedMessages,
    handleSubmit,
    messages,
    messagesPagination.currentPage,
    messagesPagination.limit,
    messagesPagination.totalPages,
    showSolvedReports,
    solvedMessages,
    solvedMessagesPagination.currentPage,
    solvedMessagesPagination.limit,
    solvedMessagesPagination.totalPages,
    submittingReport,
  ]);

  if (loadingMessages || loadingSolvedMessages) {
    return (
      <Container>
        <Spin indicator={antIcon} />
      </Container>
    );
  }

  return (
    <Container>
      <ToogleViewerButton
        onClick={() => {
          setShowSolvedReports(oldState => !oldState);
        }}
        $danger={showSolvedReports}
      >
        {!showSolvedReports ? (
          <>
            <FiCheckCircle size={16} />
            Visualizar denúncias solucionadas
          </>
        ) : (
          <>
            <FiFlag size={16} />
            Voltar para as denúncias em aberto
          </>
        )}
      </ToogleViewerButton>
      {reportedMessagesListViewer}
    </Container>
  );
}

export default ChatAthletesOfTheWeek;
