export default {
  colors: {
    primary: {
      primary0: '#009420',
      'primary+1': '#00AD26',
      'primary-1': '#007A1B',
    },
    secondary: {
      secondary0: '#FFA007',
      'secondary+1': '#FFAC26',
      'secondary-1': '#D98400',
    },
    light: {
      light0: '#fff',
      'light-1': '#F2F2F2',
      'light-2': '#E5E5E5',
    },
    dark: {
      dark0: '#2B2B2B',
      'dark+1': '#454545',
      'dark+2': '#5E5E5E',
      'dark+3': '#787878',
      'dark+4': '#919191',
    },
    danger: 'rgb(255, 77, 79)',
  },
  typograph: {
    sizes: {
      h2: 67,
      h3: 50,
      h4: 38,
      h5: 28,
      h6: 21,
      p: 16,
      small: 14,
    },
    weights: {
      light: 300,
      medium: 400,
      bold: 700,
    },
  },
};
