import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Breadcrums from '~/components/Breadcrumbs';

import api from '~/services/api';

import Input from '~/components/Input';
import DatePicker from '~/components/DatePicker';
import {
  Container,
  TitlePageContainer,
  Content,
  MatchInfoContainer,
  ButtonsContainer,
} from './styles';

import AdversarySelection from './AdversarySelection';

function disabledDateTime() {
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  return {
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    disabledSeconds: () => range(0, 60),
  };
}

function eneableFinishButton({ touched, errors }) {
  if (!touched.info) {
    return false;
  }

  if (!errors.info) {
    return true;
  }

  return false;
}

function New({ match }) {
  const history = useHistory();
  const firstRender = useRef(true);

  const myTeam = useSelector(state => state.team.data);

  const [teams, setTeams] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [teamsPagination, setTeamsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [championship, setChampionship] = useState(null);
  const [loadingChampionship, setLoadingChampionship] = useState(true);

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a criação dessa partida?',
      content: 'Todos os dados inseridos serão perdidos',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  function handleBeforeUnload(e) {
    e.returnValue = '';
  }

  const handleGetTeams = useCallback(
    async (page = 1) => {
      setLoadingTeams(true);
      try {
        const { data } = await api.get('/api/team', {
          params: {
            page,
            limit: 10,
            teamType: 'amateur',
          },
        });
        setTeams(oldTeams =>
          [...oldTeams, ...data.docs].filter(team => team._id !== myTeam?._id)
        );
        setTeamsPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        // showToast({
        //   type: 'error',
        //   title: 'Ocorreu um erro ao carregar os campeonatos',
        //   description: 'Recarregue a página!',
        // });
        toast.error(
          'Ocorreu um erro ao carregar os times. Recarregue a página!'
        );
      }
      setLoadingTeams(false);
    },
    [myTeam._id]
  );

  const handleGetAmateurChampionship = useCallback(async () => {
    try {
      const { data } = await api.get(`/api/championship/AMATEUR`);
      setChampionship(data);
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro ao carregar os campeonatos',
      //   description: 'Recarregue a página!',
      // });
      toast.error('Ocorreu um erro inesperado. Recarregue a página!');
    }
    setLoadingChampionship(false);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      handleGetTeams();
      handleGetAmateurChampionship();
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [handleGetAmateurChampionship, handleGetTeams, unblockPage]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { info, away } = values;

      const body = {
        local: myTeam?._id,
        away,
        ...info,
      };

      try {
        await api.post(`/api/championship/${championship._id}/games`, body);

        toast.success('Partida criada com sucesso!');
        actions.setSubmitting(false);

        window.removeEventListener('beforeunload', handleBeforeUnload);
        unblockPage();

        history.push('/my_matches');
      } catch (error) {
        toast.error('Aconteceu um erro inesperado ao criar a partida!');
      }
    },
    [championship, history, myTeam, unblockPage]
  );

  return (
    <Container>
      <Breadcrums match={match} />
      <TitlePageContainer>
        <h4>Nova partida</h4>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Formik
          initialValues={{
            info: {
              place: '',
              date: '',
              address: '',
            },
            away: '',
          }}
          validationSchema={Yup.object().shape({
            away: Yup.string().required('Selecione o adversário'),
            info: Yup.object().shape({
              place: Yup.string().required('O local da partida é obrigatório'),
              address: Yup.string().required('O endereço é obrigatório'),
              date: Yup.mixed().required('A data da partida é obrigatória'),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <AdversarySelection
                formikProps={formikProps}
                handleGetTeams={handleGetTeams}
                loadingTeams={loadingTeams || loadingChampionship}
                teams={teams}
                teamsPagination={teamsPagination}
              />
              {formikProps.values?.away && (
                <MatchInfoContainer>
                  <FastField name="info.place">
                    {({ field, meta }) => {
                      return (
                        <Input
                          {...field}
                          label="Local da partida"
                          placeholder="ex.: Estádio A"
                          identifier="matchPlace"
                          type="text"
                          error={meta?.touched && meta?.error && meta?.error}
                        />
                      );
                    }}
                  </FastField>
                  <FastField name="info.date">
                    {({ field, meta }) => {
                      return (
                        <DatePicker
                          {...field}
                          label="Data e hora"
                          identifier="matchInfo"
                          error={meta?.touched && meta?.error}
                          onChange={momentDate => {
                            if (momentDate) {
                              momentDate.set('second', 0);
                            }
                            formikProps.setFieldValue('info.date', momentDate);
                          }}
                          showTime
                          disabledTime={disabledDateTime}
                          format="dddd, DD/MM/YYYY [às] HH:mm:ss"
                        />
                      );
                    }}
                  </FastField>
                  <FastField name="info.address">
                    {({ field, meta }) => {
                      return (
                        <Input
                          {...field}
                          label="Endereço da partida"
                          placeholder="Cidade, Estado, País"
                          identifier="matchAddress"
                          type="text"
                          error={meta?.touched && meta?.error && meta?.error}
                        />
                      );
                    }}
                  </FastField>
                  <ButtonsContainer>
                    <Button
                      disabled={
                        !eneableFinishButton(formikProps) ||
                        formikProps.isSubmitting
                      }
                      type="primary"
                      htmlType="submit"
                    >
                      {!formikProps.isSubmitting
                        ? 'Criar partida'
                        : 'Carregando...'}
                    </Button>
                  </ButtonsContainer>
                </MatchInfoContainer>
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default New;
