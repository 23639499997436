const environment = {};

if (process.env.NODE_ENV === 'development') {
  environment.API_URL = 'http://localhost:8000';
  environment.SOCKET_URL = 'http://localhost:8001';
} else if (process.env.NODE_ENV === 'production') {
  environment.API_URL = 'https://adm-l.titan369.com/v1';
  environment.SOCKET_URL = 'https://ws-l.titan369.com';
}

environment.IMAGE_SERVER_URL = `${environment.API_URL}/api/file/`;
environment.GOOGLE_MAPS_API_KEY = 'AIzaSyA-0ytYoIIX-JjxTUCdh9KZTlOfMRlU3JY';

export default environment;
