import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { GiCardRandom } from 'react-icons/gi';
import { IoIosFlag } from 'react-icons/io';
import { FaFlagCheckered } from 'react-icons/fa';

import CustomAntButton from '~/components/CustomAntButton';

import {
  Container,
  Header,
  Content,
  QuantityOfStatisticItemSelectorContainer,
  SelectQuantitityOfStatisticItemContainer,
  // QuantityOfStatisticSelectorContainer,
  // SelectQuantitityOfStatisticContainer,
} from './styles';

const CoachMode = ({ gameToBeFinished, handleChangeQuantityOfStatistic }) => {
  return (
    <Container>
      <Header>
        <h6>Estatísticas</h6>
      </Header>
      <Content>
        <SelectQuantitityOfStatisticItemContainer $typeOfStatistic="yellowCards">
          <div>
            <GiCardRandom size={21} />
          </div>
          <strong>Cartão amarelo</strong>
          <QuantityOfStatisticItemSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'yellowCards',
                  gameToBeFinished.yellowCards - 1
                )
              }
              disabled={gameToBeFinished.yellowCards === 0}
            />
            <h6>{gameToBeFinished.yellowCards}</h6>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'yellowCards',
                  gameToBeFinished.yellowCards + 1
                )
              }
            />
          </QuantityOfStatisticItemSelectorContainer>
        </SelectQuantitityOfStatisticItemContainer>
        <SelectQuantitityOfStatisticItemContainer $typeOfStatistic="redCards">
          <div>
            <GiCardRandom size={21} />
          </div>
          <strong>Cartão vermelho</strong>
          <QuantityOfStatisticItemSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'redCards',
                  gameToBeFinished.redCards - 1
                )
              }
              disabled={gameToBeFinished.redCards === 0}
            />
            <h6>{gameToBeFinished.redCards}</h6>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'redCards',
                  gameToBeFinished.redCards + 1
                )
              }
            />
          </QuantityOfStatisticItemSelectorContainer>
        </SelectQuantitityOfStatisticItemContainer>
        <SelectQuantitityOfStatisticItemContainer $typeOfStatistic="offside">
          <div>
            <FaFlagCheckered size={16} />
          </div>
          <strong>Impedimento</strong>
          <QuantityOfStatisticItemSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfStatistic(
                  'offsideNumber',
                  gameToBeFinished.offsideNumber - 1
                );
              }}
              disabled={gameToBeFinished.offsideNumber === 0}
            />
            <h6>{gameToBeFinished.offsideNumber}</h6>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfStatistic(
                  'offsideNumber',
                  gameToBeFinished.offsideNumber + 1
                );
              }}
            />
          </QuantityOfStatisticItemSelectorContainer>
        </SelectQuantitityOfStatisticItemContainer>
        <SelectQuantitityOfStatisticItemContainer $typeOfStatistic="corner">
          <div>
            <IoIosFlag size={21} />
          </div>
          <strong>Escanteio</strong>
          <QuantityOfStatisticItemSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfStatistic(
                  'cornerNumber',
                  gameToBeFinished.cornerNumber - 1
                );
              }}
              disabled={gameToBeFinished.cornerNumber === 0}
            />
            <h6>{gameToBeFinished.cornerNumber}</h6>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfStatistic(
                  'cornerNumber',
                  gameToBeFinished.cornerNumber + 1
                );
              }}
            />
          </QuantityOfStatisticItemSelectorContainer>
        </SelectQuantitityOfStatisticItemContainer>
      </Content>
    </Container>
  );
};

export default CoachMode;
