import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Container, TitlePageContainer, Content } from './styles';

import Steps from '~/components/Steps';

import api from '~/services/api';

import Image from './Image';
import AdvertSettings from './AdvertSettings';
import Confirm from './Confirm';

function NewAdvert({ setAdvertisements }) {
  const history = useHistory();
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar o cadastro desse anúncio?',
      content: 'Todos os dados inseridos serão perdidos',
      cancelText: 'Não',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  function handleBeforeUnload(e) {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  const handleToogleCurrentStep = useCallback(newCurrentStep => {
    if (newCurrentStep < 0) {
      newCurrentStep = 0;
    }

    setCurrentStep(newCurrentStep);
  }, []);

  async function handleSubmit(values, actions) {
    const { image, advertSettings } = values;

    let photoId = null;

    if (image.file) {
      const formData = new FormData();
      formData.append('file', image.file);
      formData.append('from', 'advert');

      try {
        const { data } = await api.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        photoId = data._id;
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao enviar a imagem do anúncio!'
        );

        return;
      }
    }

    const body = {
      title: advertSettings.title,
      url: advertSettings.url,
      weight: advertSettings.weight,
      endDate: advertSettings.endDate.toDate(),
      upload: photoId,
      sponsor: params.id,
    };

    try {
      const { data } = await api.post('/api/slide-main', body);

      toast.success('Anúncio atualizado com sucesso!');
      actions.setSubmitting(false);
      setAdvertisements(oldState => [data, ...oldState]);

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();

      history.push(`/sponsors/${params.id}`);
    } catch (error) {
      console.log(error);

      toast.error('Aconteceu um erro inesperado ao atualizar o anúncio!');
    }
  }

  return (
    <Container>
      <TitlePageContainer>
        <h5>Novo anúncio</h5>
        <Button
          onClick={() => history.push(`/sponsors/${params.id}`)}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Imagem' },
            { title: 'Configurações do anúncio' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          initialValues={{
            advertSettings: {
              title: '',
              url: '',
              weight: 1,
              endDate: moment()
                .add(7, 'days')
                .set('second', 0),
            },
            image: {
              file: null,
              previewUrl: '',
            },
          }}
          validationSchema={Yup.object().shape({
            advertSettings: Yup.object().shape({
              endDate: Yup.date()
                .typeError('A data informada é inválida')
                .required('A data de expiração é obrigatória'),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <Image
                  formik={formikProps}
                  nextStep={() => handleToogleCurrentStep(1)}
                />
              )}
              {currentStep === 1 && (
                <AdvertSettings
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(0)}
                  nextStep={() => handleToogleCurrentStep(2)}
                />
              )}
              {currentStep === 2 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(1)}
                  onSubmit={formikProps.handleSubmit}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default NewAdvert;
