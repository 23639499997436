import React from 'react';

import { FastField, Field } from 'formik';

import Input from '../../../components/Input';
import CustomAntButton from '../../../components/CustomAntButton';
import Radio from '../../../components/Radio';

import { ButtonsContainer } from '../styles';

function Responsible({
  formikProps,
  nextStep,
  prevStep,
  setFocusedField,
  focusedField,
}) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.responsible) {
      return false;
    }

    if (!errors.responsible) {
      return true;
    }

    return false;
  }

  return (
    <>
      <p>Agora, informe seus dados pessoais</p>

      <FastField name="responsible.altName">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome"
              placeholder="Digite seu nome"
              id="SignInAltName"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('responsible.altName')}
            />
          );
        }}
      </FastField>
      <Field name="responsible.gender">
        {({ field, meta }) => {
          return (
            <Radio
              {...field}
              items={[
                {
                  value: 'M',
                  label: 'Masculino',
                },
                {
                  value: 'F',
                  label: 'Feminino',
                },
                {
                  value: 'O',
                  label: 'Outro',
                },
              ]}
              label="Sexo"
              error={meta?.touched && meta?.error && meta?.error}
              onChange={e => {
                if (focusedField !== 'responsible.gender') {
                  setFocusedField('responsible.gender');
                }
                field.onChange(e);
              }}
            />
          );
        }}
      </Field>
      <ButtonsContainer>
        <CustomAntButton onClick={prevStep} type="default">
          Voltar
        </CustomAntButton>
        <CustomAntButton
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formikProps)}
        >
          Próximo
        </CustomAntButton>
      </ButtonsContainer>
    </>
  );
}

export default Responsible;
