import React from 'react';
import { Button } from 'antd';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Container, SessionHeader, SessionItems } from './styles';

import * as formatters from '~/utils/formatters';

function Details({ admin, routeMatch }) {
  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>Informações do usuário</h6>
          <Link to={`${routeMatch.url}/edit_user_informations`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome de usuário</small>
            <p>{admin?.userInformations?.username}</p>
          </li>
          <li>
            <small>Email</small>
            <p>{admin?.userInformations?.email}</p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>Dados pessoais</h6>
          <Link to={`${routeMatch.url}/edit_personal_data`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome</small>
            <p>{admin?.personalData?.name}</p>
          </li>
          <li>
            <small>Sexo</small>
            <p>{formatters.formatGender(admin?.personalData?.gender)}</p>
          </li>
          <li>
            <small>CPF</small>
            <p>{formatters.formatCpf(admin?.personalData?.docNumber)}</p>
          </li>
          <li>
            <small>Telefone</small>
            <p>{formatters.formatPhone(admin?.personalData?.phone)}</p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>Permissões</h6>
          <Link to={`${routeMatch.url}/edit_permissions`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          {admin?.permissions?.manager_user && <p>Gestão de usuários</p>}
          {admin?.permissions?.manager_admin && (
            <p>Gestão de administradores</p>
          )}
          {admin?.permissions?.manager_sponsor && (
            <p>Gestão de patrocinadores</p>
          )}
          {admin?.permissions?.manager_team && <p>Gestão de times</p>}
        </SessionItems>
      </div>
    </Container>
  );
}

export default Details;
