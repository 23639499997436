import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import Input from '~/components/Input';
import { Container, ForgotPasswordButton } from './styles';

import * as AuthActions from '~/store/modules/auth/actions';

import logo from '~/assets/logo.svg';

import SignInValidationSchema from './validation';

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ user, password }) {
    dispatch(AuthActions.signInRequest(user, password));
  }

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: SignInValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <img src={logo} alt="GoBarber" />
      <form onSubmit={formik.handleSubmit}>
        <Input
          label="Nome de usuário, email ou CPF"
          identifier="SignInEmail"
          name="user"
          type="text"
          placeholder="Digite seu nome de usuário, email ou CPF"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.user}
          error={
            formik?.touched?.user && formik?.errors?.user && formik.errors.user
          }
        />
        <Input
          label="Senha"
          identifier="SignInPassword"
          name="password"
          type="password"
          placeholder="Sua senha secreta"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          error={
            formik?.touched?.password &&
            formik?.errors?.password &&
            formik.errors.password
          }
        />

        <Button htmlType="submit" type="primary" disabled={!!loading}>
          {loading ? 'Carregando...' : 'Acessar'}
        </Button>
      </form>
      <ForgotPasswordButton onClick={() => {}}>
        Esqueceu a senha?
      </ForgotPasswordButton>
    </Container>
  );
}
