import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Checkbox } from 'antd';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

import Env from '~/config/Environment';
import DefaultAvatar from '~/assets/DefaultAvatar.svg';
import api from '~/services/api';

import CustomAntButton from '~/components/CustomAntButton';

import { Container, QuantityOfPlayerGoalsSelectorContainer } from './styles';

function getPlayerPositionLabel(playerPosition) {
  switch (playerPosition) {
    case 'G':
      return 'Goleiro';
    case 'D':
      return 'Defesa';
    case 'M':
      return 'Meio campo';
    case 'A':
      return 'Ataque';
    default:
      return playerPosition;
  }
}

function AthleteCardPlayer({ athlete, cardForm, setCardForm, disabled }) {
  const { params } = useRouteMatch();

  const handleSubmitChangesOnAthleteScore = useCallback(
    async (athleteScore, status) => {
      const body = {
        athlete: {
          _team: athlete?._team?._id,
          _athlete: athlete?._athletes?._id,
          athleteScore,
          status,
        },
      };

      try {
        await api.put(`/api/end-athlete/${params.id}`, body);

        toast.success('Dados da cartela atualizados com sucesso!');
      } catch (error) {
        toast.error('Aconteceu um erro inesperado ao atualizar os dados!');
      }
    },
    [athlete._athletes._id, athlete._team._id, params.id]
  );

  const submitChangesDebounced = useMemo(() => {
    return _.debounce(handleSubmitChangesOnAthleteScore, 1000);
  }, [handleSubmitChangesOnAthleteScore]);

  const handleChangeQuantityOfPlayerGoals = useCallback(
    (_athleteId, score) => {
      if (score < 0) {
        return;
      }

      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map(athl => {
          if (athl._athletes._id === _athleteId) {
            return {
              ...athl,
              athleteScore: score,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
      submitChangesDebounced.cancel();
      submitChangesDebounced(score, true);
    },
    [cardForm, setCardForm, submitChangesDebounced]
  );

  const handleChangeStatusOfPlayer = useCallback(
    (_athleteId, status) => {
      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map(athl => {
          if (athl._athletes._id === _athleteId) {
            return {
              ...athl,
              athleteScore: 0,
              status,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
      submitChangesDebounced.cancel();
      const scoreWhenStatusIsChanged = status ? 0 : null;
      submitChangesDebounced(scoreWhenStatusIsChanged, status);
    },
    [cardForm, setCardForm, submitChangesDebounced]
  );

  return (
    <Container key={athlete?._athletes?._id} $status={athlete.status}>
      <div>
        <div>
          <img
            src={
              athlete?._athletes?.photo
                ? Env.IMAGE_SERVER_URL + athlete._athletes.photo
                : DefaultAvatar
            }
            alt={athlete?._athletes?.name || ''}
          />
        </div>
        <div>
          <strong>{athlete?._athletes?.name}</strong>
          <small>
            Camisa - {athlete?._athletes?.number} ·{' '}
            {getPlayerPositionLabel(athlete?._athletes?.position)}
          </small>
          <div>
            <img src={athlete?._team?.image} alt={athlete?._team?.name || ''} />
            <p>{athlete?._team?.name}</p>
          </div>
        </div>
        {athlete.status && (
          <QuantityOfPlayerGoalsSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfPlayerGoals(
                  athlete?._athletes?._id,
                  athlete.athleteScore - 1
                )
              }
              disabled={athlete.athleteScore === 0 || disabled}
            />
            <h6>{athlete.athleteScore}</h6>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfPlayerGoals(
                  athlete?._athletes?._id,
                  athlete.athleteScore + 1
                )
              }
              disabled={disabled}
            />
          </QuantityOfPlayerGoalsSelectorContainer>
        )}
      </div>
      <Checkbox
        checked={athlete.status}
        onChange={e =>
          handleChangeStatusOfPlayer(athlete?._athletes?._id, e.target.checked)
        }
        disabled={disabled}
      >
        Jogo iniciado ou finalizado
      </Checkbox>
    </Container>
  );
}

export default AthleteCardPlayer;
