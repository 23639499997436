import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import { Container, TitlePageContainer, Content } from './styles';

import Breadcrums from '~/components/Breadcrumbs';
import Steps from '~/components/Steps';

import api from '~/services/api';

import { validateCnpj } from '~/utils/validate';

import UserInformations from './UserInformations';
import CompanyData from './CompanyData';
import Avatar from './Avatar';
import Confirm from './Confirm';

function New({ match }) {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState({
    username: false,
    email: false,
    docNumber: false,
  });
  const [focusedField, setFocusedField] = useState(null);

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar o cadastro desse patrocinador?',
      content: 'Todos os dados inseridos serão perdidos',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  function handleBeforeUnload(e) {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  async function checkIfFieldIsAvailable(value, fieldName, resolve, reject) {
    const params = {
      username: fieldName === 'username' ? value : undefined,
      email: fieldName === 'email' ? value : undefined,
      docNumber: fieldName === 'docNumber' ? value : undefined,
    };

    try {
      setLoadingsOfAsyncValidations({
        username: !!(fieldName === 'username'),
        email: !!(fieldName === 'email'),
        docNumber: !!(fieldName === 'docNumber'),
      });
      const response = await api.get('/api/account/checker', {
        params,
      });

      resolve(!response.data.length);
      setLoadingsOfAsyncValidations({
        username: false,
        email: false,
        docNumber: false,
      });
    } catch (error) {
      setLoadingsOfAsyncValidations({
        username: false,
        email: false,
        docNumber: false,
      });
      reject(false);
      toast.error(
        'Ocorreu um erro inesperado. Atualize a página e tente novamente!'
      );
    }
  }

  const usernameValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, []);

  const emailValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, []);

  const docNumberValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, []);

  const handleToogleCurrentStep = useCallback(newCurrentStep => {
    if (newCurrentStep < 0) {
      newCurrentStep = 0;
    }

    setCurrentStep(newCurrentStep);
  }, []);

  async function handleSubmit(values, actions) {
    const { userInformations, companyData, avatar } = values;

    let photoId = null;

    if (avatar.file) {
      const formData = new FormData();
      formData.append('file', avatar.file);
      formData.append('from', 'userAvatar');

      try {
        const { data } = await api.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        photoId = data._id;
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao enviar o avatar do usuário!'
        );

        return;
      }
    }

    const body = {
      username: userInformations.username,
      email: userInformations.email,
      password: userInformations.password,
      name: companyData.name,
      altName: companyData.altName,
      docNumber:
        companyData.docNumber &&
        companyData.docNumber
          .replace(/\./g, '')
          .replace(/\//g, '')
          .replace(/-/g, ''),
      phones: [
        companyData.phone &&
          companyData.phone
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/-/g, ''),
      ],
      url: companyData.url,
      photo: photoId,
    };

    try {
      await api.post('/api/sponsor-admin', body);

      toast.success('Patrocinador criado com sucesso!');
      actions.setSubmitting(false);

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();

      history.push('/sponsors');
    } catch (error) {
      console.log(error);

      toast.error('Aconteceu um erro inesperado ao cadastrar o patrocinador!');
    }
  }

  return (
    <Container>
      <Breadcrums match={match} />
      <TitlePageContainer>
        <h4>Novo patrocinador</h4>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Informações do usuário' },
            { title: 'Dados da empresa' },
            { title: 'Avatar' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          initialValues={{
            userInformations: {
              username: '',
              email: '',
              password: '',
              confirmPassword: '',
            },
            companyData: {
              name: '',
              altName: '',
              docNumber: '',
              phone: '',
              url: '',
            },
            avatar: {
              file: null,
              previewUrl: '',
            },
          }}
          validationSchema={Yup.object().shape({
            userInformations: Yup.object().shape({
              username: Yup.string()
                .required('O nome do usuário é obrigatório')
                .test(
                  'usernameUnavailable',
                  'Esse nome de usuário já está sendo utilizado',
                  function validate(value) {
                    usernameValidationDebounced.cancel();
                    if (focusedField === 'userInformations.username') {
                      if (!value) {
                        return true;
                      }

                      return new Promise((resolve, reject) =>
                        usernameValidationDebounced(
                          value,
                          'username',
                          resolve,
                          reject
                        )
                      );
                    }
                    return true;
                  }
                ),
              email: Yup.string()
                .required('O email é obrigatório')
                .email('O email informado não é válido')
                .test(
                  'emailUnavailable',
                  'Esse email já está sendo utilizado',
                  async function validate(value) {
                    emailValidationDebounced.cancel();
                    if (focusedField === 'userInformations.email') {
                      if (
                        !value ||
                        !(await Yup.string()
                          .email()
                          .isValid(value))
                      ) {
                        return true;
                      }

                      return new Promise((resolve, reject) =>
                        emailValidationDebounced(
                          value,
                          'email',
                          resolve,
                          reject
                        )
                      );
                    }
                    return true;
                  }
                ),
              password: Yup.string()
                .min(6, 'A senha precisa ter mais de 6 dígitos')
                .required('A senha é obrigatória'),
              confirmPassword: Yup.string().when(
                'password',
                (password, field) => {
                  return password
                    ? field
                        .required('A confirmação da senha é obrigatória')
                        .oneOf(
                          [Yup.ref('password')],
                          'A confirmação da senha está incorreta'
                        )
                    : field;
                }
              ),
            }),
            companyData: Yup.object().shape({
              name: Yup.string().required('O nome é obrigatório'),
              altName: Yup.string().required('O nome fantasia é obrigatório'),
              docNumber: Yup.string()
                .required('O CNPJ é obrigatório')
                .transform(value =>
                  value
                    .replace(/\./g, '')
                    .replace(/\//g, '')
                    .replace(/-/g, '')
                )
                .test('cnpj', 'O CNPJ informado é inválido', value => {
                  const cnpjValid = validateCnpj(value);

                  if (cnpjValid === 'valid') {
                    return true;
                  }
                  return false;
                })
                .test(
                  'docNumberUnavailable',
                  'Esse CNPJ já está sendo utilizado',
                  function validate(value) {
                    docNumberValidationDebounced.cancel();
                    if (focusedField === 'companyData.docNumber') {
                      if (!value || validateCnpj(value) !== 'valid') {
                        return true;
                      }

                      return new Promise((resolve, reject) =>
                        docNumberValidationDebounced(
                          value,
                          'docNumber',
                          resolve,
                          reject
                        )
                      );
                    }
                    return true;
                  }
                ),
              phone: Yup.string()
                .required('O telefone é obrigatório')
                .transform(value =>
                  value
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .replace(/-/g, '')
                )
                .matches(/[0-9]{11}/, {
                  excludeEmptyString: true,
                  message: 'O telefone informado é inválido',
                }),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <UserInformations
                  formik={formikProps}
                  nextStep={() => handleToogleCurrentStep(1)}
                  loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 1 && (
                <CompanyData
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(0)}
                  nextStep={() => handleToogleCurrentStep(2)}
                  loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 2 && (
                <Avatar
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(1)}
                  nextStep={() => handleToogleCurrentStep(3)}
                />
              )}
              {currentStep === 3 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(2)}
                  onSubmit={formikProps.handleSubmit}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default New;
