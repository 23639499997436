import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;

  label {
    ${props =>
      props.error &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }

  > span {
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;
