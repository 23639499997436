import React from 'react';
import { Button } from 'antd';
import { FastField, Field } from 'formik';

import Input from '~/components/Input';

import { ButtonsContainer } from '../styles';

function UserInformations({
  formik,
  nextStep,
  loadingsOfAsyncValidations,
  setFocusedField,
}) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.userInformations) {
      return false;
    }

    if (!errors.userInformations) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Field name="userInformations.username">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome de usuário"
              placeholder="Digite um nome de usuário"
              identifier="username"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.username}
              onFocus={() => setFocusedField('userInformations.username')}
            />
          );
        }}
      </Field>
      <Field name="userInformations.email">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Email"
              placeholder="example@example.com"
              identifier="email"
              type="email"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.email}
              onFocus={() => setFocusedField('userInformations.email')}
            />
          );
        }}
      </Field>
      <FastField name="userInformations.password">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Senha"
              placeholder="No mínimo 6 dígitos"
              identifier="password"
              type="password"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.password}
              onFocus={() => setFocusedField('userInformations.password')}
            />
          );
        }}
      </FastField>
      <FastField name="userInformations.confirmPassword">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Confirmar senha"
              placeholder="Confirme a senha informada anteriormente"
              identifier="confirmPassword"
              type="password"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.confirmPassword}
              onFocus={() =>
                setFocusedField('userInformations.confirmPassword')
              }
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default UserInformations;
