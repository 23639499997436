import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';

export const Button = styled(AntButton)`
  ${props =>
    props.textprimary === 'true' &&
    css`
      color: ${props.theme.colors.primary.primary0};

      &:hover,
      &:focus,
      &:active {
        color: ${props.theme.colors.primary['primary-1']};
      }
    `}

  ${props =>
    props.icon &&
    css`
      line-height: 0;
    `}
`;
