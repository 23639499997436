import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

// import { IPlayCoachGoalGameState } from '..';
// import { ITeam } from '../..';

import CustomAntButton from '~/components/CustomAntButton';

import {
  Container,
  Header,
  Content,
  ScoreboardContainer,
  ScoreTeamSelectorContainer,
  ScoreboardTeam,
} from './styles';

// interface IPlayCoachGoalMode {
//   playCoachGoalGame: IPlayCoachGoalGameState;
//   handleChangeScoreOfTeamInPlayCoachGoal(
//     team: 'local' | 'away',
//     score: number,
//   ): void;
//   localTeam: ITeam;
//   awayTeam: ITeam;
// }

const Scoreboard = ({
  match,
  gameToBeFinished,
  handleChangeScoreOfTeam,
  // playCoachGoalGame,
  // localTeam,
  // awayTeam,
}) => {
  return (
    <Container>
      <Header>
        <h6>Placar</h6>
      </Header>
      <Content>
        <ScoreboardContainer>
          <ScoreboardTeam>
            <div>
              <img src={match.local.image} alt={match.local.name} />
            </div>
            <strong>{match.local.abbrev}</strong>
          </ScoreboardTeam>
          <ScoreTeamSelectorContainer>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeam(
                  'local',
                  gameToBeFinished.localScore + 1
                )
              }
            />
            <h6>{gameToBeFinished.localScore}</h6>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeam(
                  'local',
                  gameToBeFinished.localScore - 1
                )
              }
              disabled={gameToBeFinished.localScore === 0}
            />
          </ScoreTeamSelectorContainer>
          <h6>VS</h6>
          <ScoreTeamSelectorContainer>
            <CustomAntButton
              type="text"
              textprimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeam('away', gameToBeFinished.awayScore + 1)
              }
            />
            <h6>{gameToBeFinished.awayScore}</h6>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeam('away', gameToBeFinished.awayScore - 1)
              }
              disabled={gameToBeFinished.awayScore === 0}
            />
          </ScoreTeamSelectorContainer>
          <ScoreboardTeam>
            <div>
              <img src={match.away.image} alt={match.away.name} />
            </div>
            <strong>{match.away.abbrev}</strong>
          </ScoreboardTeam>
        </ScoreboardContainer>
      </Content>
    </Container>
  );
};

export default Scoreboard;
