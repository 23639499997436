import React from 'react';

import { FastField, Field } from 'formik';

import Input from '../../../components/Input';
import CustomAntButton from '../../../components/CustomAntButton';

import { ButtonsContainer } from '../styles';

function User({
  formikProps,
  prevStep,
  loadingsOfAsyncValidations,
  setFocusedField,
  focusedField,
}) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.user) {
      return false;
    }

    if (!errors.user) {
      return true;
    }

    return false;
  }

  return (
    <>
      <p>Para finalizar, informe os dados para login na plataforma</p>

      <Field name="user.username">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome de usuário"
              placeholder="Digite um nome de usuário"
              id="SignUpUsername"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations.username}
              onFocus={() => setFocusedField('user.username')}
              onChange={e => {
                e.target.value = e.target.value
                  .replace(/\s+/g, '')
                  .toLowerCase();
                field.onChange(e);
              }}
            />
          );
        }}
      </Field>
      <FastField name="user.password">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Senha"
              placeholder="Digite uma senha secreta"
              id="SignUpPassword"
              type="password"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('user.password')}
            />
          );
        }}
      </FastField>
      <FastField name="user.confirmPassword">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Confirmar senha"
              placeholder="Confirme sua senha secreta"
              id="SignUpConfirmPassword"
              type="password"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('user.confirmPassword')}
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <CustomAntButton onClick={prevStep} type="default">
          Voltar
        </CustomAntButton>
        <CustomAntButton
          type="primary"
          htmlType="submit"
          disabled={
            formikProps.isSubmitting || !eneableNextStepButton(formikProps)
          }
        >
          {!formikProps.isSubmitting ? 'Cadastrar time' : 'Cadastrando time...'}
        </CustomAntButton>
      </ButtonsContainer>
    </>
  );
}

export default User;
