import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import api from '~/services/api';

import PlayersList from './PlayersList';
import UpdatePlayerAvatar from './UpdatePlayerAvatar';

import { Container } from './styles';

const { confirm } = Modal;

function Players({ players, setTeam }) {
  const { params } = useRouteMatch();

  const myTeam = useSelector(state => state.team.data);

  const [
    playersWithControllerOptions,
    setPlayersWithControllerOptions,
  ] = useState([]);

  const [playerToEditAvatar, setPlayerToEditAvatar] = useState(null);

  useEffect(() => {
    setPlayersWithControllerOptions(oldState => {
      return players.map((player, index) => {
        const playerWithControllerOptions = oldState[index] || null;
        return {
          ...player,
          collapsedOnList: playerWithControllerOptions
            ? playerWithControllerOptions.collapsedOnList
            : false,
          inEditing: playerWithControllerOptions
            ? playerWithControllerOptions.inEditing
            : false,
        };
      });
    });
  }, [players]);

  const handleEditPlayerData = useCallback(_playerId => {
    setPlayersWithControllerOptions(oldState => {
      const currentPlayerIndex = oldState.findIndex(
        oldStatePlayer => oldStatePlayer._id === _playerId
      );
      const updatedPlayersWithControllerOptions = [...oldState];
      updatedPlayersWithControllerOptions[
        currentPlayerIndex
      ].collapsedOnList = false;
      updatedPlayersWithControllerOptions[
        currentPlayerIndex
      ].inEditing = !updatedPlayersWithControllerOptions[currentPlayerIndex]
        .inEditing;

      return updatedPlayersWithControllerOptions;
    });
  }, []);

  const handleSubmitUpdatePlayer = useCallback(
    async values => {
      const { id } = params;
      const teamId = myTeam?._id || id;

      if (!teamId) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse jogador, atualize a página e tente novamente!'
        );
      }

      await new Promise(resolve => {
        confirm({
          title: 'Deseja realmente atualizar os dados desse jogador?',
          icon: <ExclamationCircleOutlined />,
          content: 'Os dados inseridos anteriormente serão substituídos',
          cancelText: 'Cancelar',
          okText: 'Atualizar dados',
          onOk() {
            resolve(true);
          },
        });
      });

      const body = {
        player: {
          ...values,
          age: values.age || '--',
          height: values.height ? `${values.height} m` : '--',
          weight: values.height ? `${values.weight} kg` : '--',
          from: values.from || '--',
        },
        status: 'update',
      };

      try {
        await api.put(`/api/players/${teamId}`, body);

        toast.success('Dados do jogador atualizados com sucesso!');
        handleEditPlayerData(body.player._id);
        setTeam(oldState => {
          const updatedPlayerIndex = oldState.players.findIndex(
            player => player._id === body.player._id
          );
          const updatedPlayers = [...oldState.players];
          updatedPlayers[updatedPlayerIndex] = {
            ...updatedPlayers[updatedPlayerIndex],
            ...body.player,
          };

          return {
            ...oldState,
            players: updatedPlayers,
          };
        });
      } catch (error) {
        toast.error(
          'Aconteceu um erro inesperado ao atualizar os dados desse jogador!'
        );
      }
      return null;
    },
    [handleEditPlayerData, myTeam, params, setTeam]
  );

  const handleSubmitDeletePlayer = useCallback(
    async _playerId => {
      const { id } = params;
      const teamId = myTeam?._id || id;

      await new Promise(resolve => {
        confirm({
          title: 'Deseja realmente excluir esse jogador?',
          icon: <ExclamationCircleOutlined />,
          content: 'Essa ação não poderá ser desfeita',
          cancelText: 'Cancelar',
          okText: 'Excluir jogador',
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve(true);
          },
        });
      });

      const body = {
        player: {
          _id: _playerId,
        },
        status: 'delete',
      };

      try {
        await api.put(`/api/players/${teamId}`, body);

        toast.success('Jogador excluído com sucesso!');
        setTeam(oldState => {
          const updatedPlayerIndex = oldState.players.findIndex(
            player => player._id === body.player._id
          );
          const updatedPlayers = [...oldState.players];
          updatedPlayers.splice(updatedPlayerIndex, 1);

          return {
            ...oldState,
            players: updatedPlayers,
          };
        });
      } catch (error) {
        toast.error('Aconteceu um erro inesperado ao excluir esse jogador!');
      }
      return null;
    },
    [myTeam, params, setTeam]
  );

  const handleToogleEditPlayerAvatar = useCallback((player = null) => {
    setPlayerToEditAvatar(player);
  }, []);

  return (
    <Container>
      {!playerToEditAvatar ? (
        <PlayersList
          playersWithControllerOptions={playersWithControllerOptions}
          setPlayersWithControllerOptions={setPlayersWithControllerOptions}
          handleEditPlayerData={handleEditPlayerData}
          handleSubmitUpdatePlayer={handleSubmitUpdatePlayer}
          handleSubmitDeletePlayer={handleSubmitDeletePlayer}
          handleToogleEditPlayerAvatar={handleToogleEditPlayerAvatar}
        />
      ) : (
        <UpdatePlayerAvatar
          player={playerToEditAvatar}
          handleToogleEditPlayerAvatar={handleToogleEditPlayerAvatar}
          setPlayersWithControllerOptions={setPlayersWithControllerOptions}
        />
      )}
    </Container>
  );
}

export default Players;
