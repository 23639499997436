import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import Input from '~/components/Input';
import api from '~/services/api';
import useBeforeUnload from '~/hooks/useBeforeUnload';
import useMergeRefs from '~/hooks/useMergeRefs';

function EditUserInformations({ sponsor, setSponsor, parentRouteMatch }) {
  const history = useHistory();
  const params = useParams();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef(null);

  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState({
    username: false,
    email: false,
  });
  const [focusedField, setFocusedField] = useState(null);
  const [haveChanges, setHaveChanges] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    currentValues => {
      if (_.isEqual(sponsor?.userInformations, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [haveChanges, sponsor.userInformations]
  );

  async function checkIfFieldIsAvailable(
    value,
    fieldName,
    resolve,
    reject,
    fieldTouched,
    setFieldTouched
  ) {
    const query = {
      username: fieldName === 'username' ? value : undefined,
      email: fieldName === 'email' ? value : undefined,
    };

    try {
      setLoadingsOfAsyncValidations({
        username: !!(fieldName === 'username'),
        email: !!(fieldName === 'email'),
      });
      const response = await api.get('/api/account/checker', {
        params: query,
      });

      resolve(!response.data.length);
      if (!fieldTouched) setFieldTouched();
      setLoadingsOfAsyncValidations({
        username: false,
        email: false,
      });
    } catch (error) {
      setLoadingsOfAsyncValidations({
        username: false,
        email: false,
      });
      reject(false);
      toast.error(
        'Ocorreu um erro inesperado. Atualize a página e tente novamente!'
      );
    }
  }

  const usernameValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, []);

  const emailValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, []);

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { id } = params;

      if (!id) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse usuário, atualize a página e tente novamente!'
        );
      }

      const body = {
        username: values?.username,
        email: values?.email,
      };

      try {
        await api.put(`/api/sponsor-admin/${id}`, body);

        toast.success('Dados atualizados com sucesso!');
        setSponsor({ ...sponsor, userInformations: body });
        actions.setSubmitting(false);
        unblockPage();

        history.push(parentRouteMatch.url);
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao atualizar o avatar do usuário!'
        );
      }
      return null;
    },
    [history, params, parentRouteMatch.url, setSponsor, sponsor, unblockPage]
  );

  const eneableSubmitButton = useCallback(
    ({ touched, errors, values }) => {
      if (
        _.isEqual(sponsor?.userInformations, values) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [sponsor.userInformations]
  );

  const handleFormikRef = node => {
    if (node !== null) {
      checkIfHaveChanges(node.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Informações do usuário</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={{
            username: sponsor?.userInformations?.username || '',
            email: sponsor?.userInformations?.email || '',
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string()
              .required('O nome do usuário é obrigatório')
              .test(
                'usernameUnavailable',
                'Esse nome de usuário já está sendo utilizado',
                function validate(value) {
                  usernameValidationDebounced.cancel();
                  if (focusedField === 'username') {
                    if (
                      !value ||
                      value === sponsor?.userInformations?.username
                    ) {
                      return true;
                    }

                    return new Promise((resolve, reject) =>
                      usernameValidationDebounced(
                        value,
                        'username',
                        resolve,
                        reject,
                        formikRef?.current?.touched?.username,
                        () =>
                          formikRef.current.setFieldTouched('username', true)
                      )
                    );
                  }
                  return true;
                }
              ),
            email: Yup.string()
              .required('O email é obrigatório')
              .email('O email informado não é válido')
              .test(
                'emailUnavailable',
                'Esse email já está sendo utilizado',
                async function validate(value) {
                  emailValidationDebounced.cancel();
                  if (focusedField === 'email') {
                    if (
                      !value ||
                      !(await Yup.string()
                        .email()
                        .isValid(value)) ||
                      value === sponsor?.userInformations?.email
                    ) {
                      return true;
                    }

                    return new Promise((resolve, reject) =>
                      emailValidationDebounced(
                        value,
                        'email',
                        resolve,
                        reject,
                        formikRef?.current?.touched?.email,
                        () => formikRef.current.setFieldTouched('email', true)
                      )
                    );
                  }
                  return true;
                }
              ),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <Field name="username">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome de usuário"
                      placeholder="Digite um nome de usuário"
                      identifier="username"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      showloading={!!loadingsOfAsyncValidations?.username}
                      onFocus={() => setFocusedField('username')}
                    />
                  );
                }}
              </Field>
              <Field name="email">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Email"
                      placeholder="example@example.com"
                      identifier="email"
                      type="email"
                      error={meta?.touched && meta?.error && meta?.error}
                      showloading={!!loadingsOfAsyncValidations?.email}
                      onFocus={() => setFocusedField('email')}
                    />
                  );
                }}
              </Field>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditUserInformations;
