import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Container,
  TitlePageContainer,
  ChampionshipsListContainer,
  ChampionshipsList,
  ChampionshipDetailContainer,
} from './styles';

import api from '~/services/api';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Championships() {
  const history = useHistory();

  const [championships, setChampionships] = useState({
    total: 0,
    currentPage: 0,
    pageSize: 0,
    items: [],
    searchCompleted: false,
  });
  const [loadingChampionships, setLoadingChampionships] = useState(true);

  async function handleGetChampionships(page = 1) {
    setLoadingChampionships(true);

    try {
      const { data } = await api.get('/api/championship', {
        params: {
          page,
        },
      });

      setChampionships({
        total: data.total,
        currentPage: data.page,
        pageSize: data.limit,
        items: data.docs,
        searchCompleted: true,
      });

      setLoadingChampionships(false);
    } catch (error) {
      setLoadingChampionships(false);
    }
  }

  useEffect(() => {
    handleGetChampionships();
  }, []);

  if (loadingChampionships) {
    return (
      <Container>
        <TitlePageContainer>
          <h4>Campeonatos</h4>
        </TitlePageContainer>
        <ChampionshipsListContainer>
          <Spin indicator={antIcon} />
        </ChampionshipsListContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TitlePageContainer>
        <h4>Campeonatos</h4>
      </TitlePageContainer>
      <ChampionshipsListContainer>
        <ChampionshipsList>
          {championships?.items.map(championship => (
            <ChampionshipDetailContainer
              onClick={() =>
                history.push(`/championships/${championship?._id}`)
              }
              key={championship?.name}
            >
              <div>
                <img
                  src={championship?.imageUrl}
                  alt={championship?.name || 'Avatar'}
                />
              </div>
              <h6>{championship?.name}</h6>
            </ChampionshipDetailContainer>
          ))}
        </ChampionshipsList>
        <Pagination
          current={championships.currentPage}
          onChange={page => {
            setChampionships({ ...championships, currentPage: page });
            handleGetChampionships(page);
          }}
          total={championships.total}
          pageSize={championships.pageSize}
        />
      </ChampionshipsListContainer>
    </Container>
  );
}

export default Championships;
