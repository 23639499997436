import React, { useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { useRouteMatch, Link } from 'react-router-dom';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { toast } from 'react-toastify';

import api from '~/services/api';
import Env from '~/config/Environment';

import CustomAntButton from '~/components/CustomAntButton';

import DefaultAvatar from '~/assets/DefaultAvatar.svg';

import {
  Container,
  TitlePageContainer,
  AthleteCardsListContainer,
  AthleteCardsList,
  AthleteCardDetailContainer,
  AthleteCardDetailMainContent,
  AthleteCardDetailMatchsContent,
  AthleteCardPlayer,
  LoadingAthleteCardDetailsContainer,
  LoadingAndNotFoundContainer,
} from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { format, parseISO } = dateFns;

const cardTypesLabels = {
  new: 'Disponível para jogar',
  running: 'Em execução',
  finished: 'Finalizada',
};

function getPlayerPositionLabel(playerPosition) {
  switch (playerPosition) {
    case 'G':
      return 'Goleiro';
    case 'D':
      return 'Defesa';
    case 'M':
      return 'Meio campo';
    case 'A':
      return 'Ataque';
    default:
      return playerPosition;
  }
}

function AtheletesOfTheWeekCardGames() {
  const { params, url } = useRouteMatch();

  const [cards, setCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [cardsPagination, setCardsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    limit: 10,
  });

  const getCards = useCallback(
    async (page = 1) => {
      setLoadingCards(true);
      try {
        const { data } = await api.get(`/api/athlete-card`, {
          params: {
            champ: params.id,
            page,
            limit: cardsPagination.limit,
          },
        });

        setCards(
          data.docs.map(cardGame => ({
            ...cardGame,
            collapsedOnList: false,
            loadingDetails: false,
            loadedDetails: false,
          }))
        );
        setCardsPagination(oldState => ({
          currentPage: data.page,
          totalPages: data.total,
          limit: oldState.limit,
        }));
        setLoadingCards(false);
      } catch (error) {
        setLoadingCards(false);
      }
      return '';
    },
    [cardsPagination.limit, params.id]
  );

  const getCardDetails = useCallback(async _cardId => {
    setCards(oldCards =>
      oldCards.map(oldCard => {
        if (oldCard._id === _cardId) {
          return {
            ...oldCard,
            collapsedOnList: true,
            loadingDetails: true,
          };
        }
        return { ...oldCard, collapsedOnList: false };
      })
    );

    // setCards(oldCards => {
    //   openedMatchCardIndex = oldCards.findIndex(
    //     card => card._id === _cardId
    //   );
    //   const updatedMatchCards = [...oldCards];
    //   updatedMatchCards[openedMatchCardIndex].collapsedOnList = true;
    //   updatedMatchCards[openedMatchCardIndex].loadingDetails = true;
    //   return updatedMatchCards;
    // });
    try {
      const { data } = await api.get(`/api/athlete-card/${_cardId}`);

      setCards(oldCards =>
        oldCards.map(oldCard => {
          if (oldCard._id === _cardId) {
            return {
              ...oldCard,
              athletes: data.doc.athletes,
              gameCount: data.cardCount,
              amount: data.amount,
              loadingDetails: false,
              loadedDetails: true,
            };
          }
          return { ...oldCard };
        })
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da cartela. Recarregue a página e tente novamente!'
      );
      setCards(oldCards =>
        oldCards.map(oldCard => {
          if (oldCard._id === _cardId) {
            return {
              ...oldCard,
              loadingDetails: false,
            };
          }
          return { ...oldCard, collapsedOnList: false };
        })
      );
    }
    return '';
  }, []);

  useEffect(() => {
    getCards();
  }, [getCards]);

  // console.log(loadingMatchCards);

  if (loadingCards) {
    return (
      <Container>
        <TitlePageContainer>
          <h4>Artilheiros da semana</h4>
          <div>
            <Link to={`${url}/new`}>
              <Button type="primary">Nova cartela</Button>
            </Link>
          </div>
        </TitlePageContainer>
        <AthleteCardsListContainer>
          <Spin indicator={antIcon} />
        </AthleteCardsListContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TitlePageContainer>
        <h4>Artilheiros da semana</h4>
        <div>
          <Link to={`${url}/new`}>
            <Button type="primary">Nova cartela</Button>
          </Link>
        </div>
      </TitlePageContainer>
      <AthleteCardsListContainer>
        <AthleteCardsList>
          {cards.length > 0 ? (
            cards.map(card => (
              <AthleteCardDetailContainer key={card._id}>
                <AthleteCardDetailMainContent
                  onClick={() => {
                    if (!card.loadingDetails && !card.loadedDetails) {
                      getCardDetails(card._id);
                      return;
                    }
                    if (card.loadedDetails) {
                      setCards(oldCards =>
                        oldCards.map(oldCard => {
                          if (oldCard._id === card._id) {
                            return {
                              ...oldCard,
                              collapsedOnList: !oldCard.collapsedOnList,
                            };
                          }
                          return { ...oldCard, collapsedOnList: false };
                        })
                      );
                    }
                  }}
                  bg={
                    card.banner?.filename &&
                    Env.IMAGE_SERVER_URL + card.banner?.filename
                  }
                  cardType={card.cardType}
                  collapsedOnList={card.collapsedOnList}
                >
                  <div>
                    <h6>{card.name}</h6>
                    {!card.collapsedOnList ? (
                      <FiChevronDown size={24} />
                    ) : (
                      <FiChevronUp size={24} />
                    )}
                  </div>
                  <small>{cardTypesLabels[card.cardType]}</small>
                  <small>
                    <b>Valor do jogo: </b>LF$ {card.price}
                  </small>
                  <small>
                    <b>Data de início da cartela: </b>
                    {format(
                      parseISO(card.startDate),
                      "cccc, dd/MM/yyyy 'às' HH:mm",
                      {
                        locale: ptLocale,
                      }
                    )}
                  </small>
                  <small>
                    <b>Data de fim da cartela: </b>
                    {format(
                      parseISO(card.endDate),
                      "cccc, dd/MM/yyyy 'às' HH:mm",
                      {
                        locale: ptLocale,
                      }
                    )}
                  </small>
                  <small>
                    <b>Cartela finalizada: </b>
                    {card.finished ? 'Sim' : 'Não'}
                  </small>
                </AthleteCardDetailMainContent>
                {card.collapsedOnList && (
                  <>
                    {card.loadingDetails ? (
                      <AthleteCardDetailMatchsContent>
                        <LoadingAthleteCardDetailsContainer>
                          <Spin indicator={antIcon} />
                        </LoadingAthleteCardDetailsContainer>
                      </AthleteCardDetailMatchsContent>
                    ) : (
                      <AthleteCardDetailMatchsContent>
                        <Link to={`/athletes_of_the_week/${card._id}`}>
                          {!card.finished && (
                            <CustomAntButton type="primary">
                              Atualizar resultados
                            </CustomAntButton>
                          )}
                        </Link>
                        {card.athletes ? (
                          card.athletes.map(athlete => (
                            <AthleteCardPlayer key={athlete?._athletes?._id}>
                              <div>
                                <img
                                  src={
                                    athlete?._athletes?.photo
                                      ? Env.IMAGE_SERVER_URL +
                                        athlete._athletes.photo
                                      : DefaultAvatar
                                  }
                                  alt={athlete?._athletes?.name || ''}
                                />
                              </div>
                              <div>
                                <strong>{athlete?._athletes?.name}</strong>
                                <small>
                                  Camisa - {athlete?._athletes?.number} ·{' '}
                                  {getPlayerPositionLabel(
                                    athlete?._athletes?.position
                                  )}
                                </small>
                                <div>
                                  <img
                                    src={athlete?._team?.image}
                                    alt={athlete?._team?.name || ''}
                                  />
                                  <p>{athlete?._team?.name}</p>
                                </div>
                              </div>
                              {athlete.status && (
                                <h6>{athlete.athleteScore}</h6>
                              )}
                            </AthleteCardPlayer>
                          ))
                        ) : (
                          <p>Nenhum artilheiro encontrado nessa cartela</p>
                        )}
                      </AthleteCardDetailMatchsContent>
                    )}
                  </>
                )}
              </AthleteCardDetailContainer>
            ))
          ) : (
            <LoadingAndNotFoundContainer>
              <div>
                <h6>
                  Não foram encontradas <span>cartelas</span> de Artilheiros da
                  Semana
                </h6>
              </div>
            </LoadingAndNotFoundContainer>
          )}
        </AthleteCardsList>
        <Pagination
          current={cardsPagination.currentPage}
          onChange={page => {
            setCardsPagination(oldState => ({
              ...oldState,
              currentPage: page,
            }));
            getCards(page);
          }}
          total={cardsPagination.totalPages}
          pageSize={cardsPagination.limit}
        />
      </AthleteCardsListContainer>
    </Container>
  );
}

export default AtheletesOfTheWeekCardGames;
