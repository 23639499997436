import React, { useCallback } from 'react';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Image, Shimmer } from 'react-shimmer';
import { FiMoreVertical, FiPlus } from 'react-icons/fi';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import {
  Container,
  SessionHeader,
  SessionItems,
  AdvertisementItemContainer,
  AdvertisementItemContent,
} from './styles';

import * as formatters from '~/utils/formatters';
import Env from '~/config/Environment';
import CustomAntButton from '~/components/CustomAntButton';
import api from '~/services/api';

const { format, parseISO } = dateFns;
const { confirm } = Modal;

function Details({ sponsor, advertisements, setAdvertisements, routeMatch }) {
  const handleFinishAdvert = useCallback(
    async advert_id => {
      try {
        await new Promise((resolve, reject) => {
          confirm({
            title: 'Deseja realmente finalizar esse anúncio?',
            icon: <ExclamationCircleOutlined />,
            content:
              'Ele não estará mais disponível na plataforma Lance Futebol',
            cancelText: 'Não',
            okText: 'Finalizar anúncio',
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve();
            },
            onCancel() {
              reject(new Error('CANCELED_ADMIN_DELETE_ACTION'));
            },
          });
        });

        await api.delete(`/api/slide-main/${advert_id}`);

        toast.success('Anúncio finalizado com sucesso!');
        setAdvertisements(oldState =>
          oldState.filter(ads => ads._id !== advert_id)
        );
      } catch (error) {
        if (error.message === 'CANCELED_ADMIN_DELETE_ACTION') {
          return null;
        }
      }

      return null;
    },
    [setAdvertisements]
  );

  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>Informações do usuário</h6>
          <Link to={`${routeMatch.url}/edit_user_informations`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome de usuário</small>
            <p>{sponsor?.userInformations?.username}</p>
          </li>
          <li>
            <small>Email</small>
            <p>{sponsor?.userInformations?.email}</p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>Dados da empresa</h6>
          <Link to={`${routeMatch.url}/edit_company_data`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Razão Social</small>
            <p>{sponsor?.companyData?.name}</p>
          </li>
          <li>
            <small>Nome Fantasia</small>
            <p>{sponsor?.companyData?.altName}</p>
          </li>
          <li>
            <small>CNPJ</small>
            <p>{formatters.formatCnpj(sponsor?.companyData?.docNumber)}</p>
          </li>
          <li>
            <small>Telefone</small>
            <p>{formatters.formatPhone(sponsor?.companyData?.phone)}</p>
          </li>
          <li>
            <small>URL</small>
            <p>
              <a
                href={sponsor?.companyData?.url || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sponsor?.companyData?.url}
              </a>
            </p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>Publicidade</h6>
          <Link to={`${routeMatch.url}/adverts/new`}>
            <Button type="primary" icon={<FiPlus height={18} />} />
          </Link>
        </SessionHeader>
        {advertisements.length > 0 ? (
          <>
            {advertisements.map(advertisement => (
              <AdvertisementItemContainer key={advertisement._id}>
                <Image
                  src={Env.IMAGE_SERVER_URL + advertisement._upload}
                  fallback={<Shimmer width={100} height={100} />}
                />
                <AdvertisementItemContent>
                  <Dropdown
                    overlay={
                      <Menu>
                        {advertisement.url && (
                          <Menu.Item>
                            <a
                              href={advertisement.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Seguir link do anúncio
                            </a>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <Link
                            to={`${routeMatch.url}/adverts/${advertisement._id}`}
                          >
                            Configurações do anúncio
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          danger
                          onClick={() => handleFinishAdvert(advertisement._id)}
                        >
                          Finalizar anúncio
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomRight"
                    arrow
                  >
                    <CustomAntButton
                      type="primary"
                      icon={<FiMoreVertical height={18} />}
                    />
                  </Dropdown>
                  <ul>
                    <li>
                      <small>Data de expiração</small>
                      <p>
                        {format(
                          parseISO(advertisement.endDate),
                          "cccc, dd/MM/yyyy 'às' HH:mm",
                          {
                            locale: ptLocale,
                          }
                        )}
                      </p>
                    </li>
                  </ul>
                </AdvertisementItemContent>
              </AdvertisementItemContainer>
            ))}
          </>
        ) : (
          <p>Nenhum anúncio ativo</p>
        )}
      </div>
    </Container>
  );
}

export default Details;
