import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Input, Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Container,
  TitlePageContainer,
  SearchAndNewUserContainer,
  UsersListContainer,
  UsersList,
  NamePageForIndicator,
  UserDetailContainer,
  UserDataContainer,
} from './styles';

import api from '~/services/api';
import Breadcrumbs from '~/components/Breadcrumbs';

import DefaultAvatar from '~/assets/DefaultAvatar.svg';

import Env from '~/config/Environment';

const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Users({ match }) {
  const history = useHistory();

  const [administrators, setAdministrators] = useState({
    total: 0,
    currentPage: 0,
    pageSize: 0,
    items: [],
    searchCompleted: false,
  });
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loadingAdministrators, setLoadingAdministrators] = useState(false);

  async function handleGetAdministrators(search) {
    setLoadingAdministrators(true);
    try {
      const { data } = await api.get('/api/admin', {
        params: {
          search,
        },
      });

      setAdministrators({
        total: data.total,
        currentPage: data.page,
        pageSize: data.limit,
        items: data.docs,
        searchCompleted: true,
      });

      setLoadingAdministrators(false);
    } catch (error) {
      setLoadingAdministrators(false);
    }
  }

  const adminSearchDebounced = useMemo(() => {
    return _.debounce(handleGetAdministrators, 500);
  }, []);

  useEffect(() => {
    adminSearchDebounced.cancel();

    if (searchInputValue.length > 3) {
      setLoadingAdministrators(true);
      adminSearchDebounced(searchInputValue);
    } else {
      setLoadingAdministrators(false);
    }

    if (!searchInputValue) {
      setAdministrators({
        total: 0,
        currentPage: 0,
        pageSize: 0,
        items: [],
        searchCompleted: false,
      });
    }
  }, [searchInputValue, adminSearchDebounced]);

  const contentPage = useMemo(() => {
    if (loadingAdministrators) {
      return <Spin indicator={antIcon} />;
    }

    if (!searchInputValue) {
      return (
        <h5>
          Pesquise por{' '}
          <NamePageForIndicator>administradores</NamePageForIndicator>{' '}
          cadastrados no Lance Futebol
        </h5>
      );
    }
    if (searchInputValue.length <= 3) {
      return <h6>Digite acima de 3 caracteres para realizar a busca</h6>;
    }

    if (administrators?.items?.length > 0) {
      return (
        <>
          <UsersList>
            {administrators?.items.map(admin => (
              <UserDetailContainer
                onClick={() =>
                  history.push(`/settings/administrators/${admin?._id}`)
                }
                key={admin?.username}
              >
                <img
                  src={
                    admin?.photo?.filename
                      ? Env.IMAGE_SERVER_URL + admin?.photo?.filename
                      : DefaultAvatar
                  }
                  alt={admin?.name || 'Avatar'}
                />
                <UserDataContainer>
                  <div>
                    <p>{admin?.name}</p>
                    {admin?.status === 0 && <span>Inativo</span>}
                  </div>
                  <span>{admin?.username}</span>
                  <span>{admin?.email}</span>
                </UserDataContainer>
              </UserDetailContainer>
            ))}
          </UsersList>
          <Pagination
            current={administrators.currentPage}
            onChange={page =>
              setAdministrators({ ...administrators, currentPage: page })
            }
            total={administrators.total}
            pageSize={administrators.pageSize}
          />
        </>
      );
    }

    if (
      administrators?.items?.length === 0 &&
      administrators?.searchCompleted
    ) {
      return <h6>Nenhum administrador encontrado</h6>;
    }

    return '';
  }, [loadingAdministrators, searchInputValue, administrators, history]);

  return (
    <Container>
      <Breadcrumbs match={match} />
      <TitlePageContainer>
        <h4>Administradores</h4>
        <Link to="/settings/administrators/new">
          <Button type="primary">Novo administrador</Button>
        </Link>
      </TitlePageContainer>
      <SearchAndNewUserContainer>
        <Search
          onChange={e => setSearchInputValue(e.target.value)}
          placeholder="Pesquisar administrador"
        />
        <Link to="/settings/administrators/new">
          <Button type="primary">Novo administrador</Button>
        </Link>
      </SearchAndNewUserContainer>
      <UsersListContainer>{contentPage}</UsersListContainer>
    </Container>
  );
}

export default Users;
