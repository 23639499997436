import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  Link,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdEdit } from 'react-icons/md';

import api from '~/services/api';
import Breadcrums from '~/components/Breadcrumbs';
import NotFoundImage from '~/assets/NotFound.svg';
import DefaultAvatar from '~/assets/DefaultAvatar.svg';

import {
  getMyTeamDataRequest,
  updateMyTeam,
} from '~/store/modules/team/actions';
import {
  Container,
  Header,
  Content,
  ImageWrapper,
  TeamInformationsHeader,
  TeamNotFoundContainer,
  ActionButtonAvatar,
} from './styles';

import Details from './Details';
import NewPlayer from './NewPlayer';
import UpdateAvatar from './UpdateAvatar';
import EditTeamData from './EditTeamData';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Team({ match }) {
  const params = useParams();
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();

  const me = useSelector(state => state.user);
  const myTeam = useSelector(state => state.team.data); // For team users

  const [teamData, setTeamData] = useState(null); // For admin users
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  const getTeamData = useCallback(async () => {
    const _id = params?.id;

    try {
      const { data } = await api.get(`/api/team/${_id}`);

      setTeamData(data);
      setLoadingTeam(false);
    } catch (error) {
      setLoadingTeam(false);
    }
    return '';
  }, [params.id]);

  const team = me.type === 'admin' ? teamData : myTeam;
  const setTeam = useCallback(
    data => {
      let updatedTeam;

      if (typeof data === 'function') {
        updatedTeam = data(team);
      } else {
        updatedTeam = data;
      }

      if (me.type === 'admin') setTeamData(updatedTeam);
      if (me.type === 'team') dispatch(updateMyTeam(updatedTeam));
    },
    [dispatch, me.type, team]
  );
  const getTeam = useCallback(() => {
    if (me.type === 'admin') return getTeamData();
    if (me.type === 'team') return dispatch(getMyTeamDataRequest(me.profile));
    return null;
  }, [dispatch, getTeamData, me.profile, me.type]);

  useEffect(() => {
    if (me.type === 'admin') {
      getTeamData();
    } else {
      setLoadingTeam(false);
    }
  }, [getTeamData, me.type]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (page.includes('/new_player')) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  if (loadingTeam) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  if (!team) {
    return (
      <Container>
        <TeamNotFoundContainer>
          <Breadcrums match={routeMatch} />
          <div>
            <img src={NotFoundImage} alt="Not Found" />
            <h6>Esse time não foi encontrado</h6>
          </div>
        </TeamNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Breadcrums match={match} />
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main' && me.type === 'team') {
                setAvatarModalVisible(true);
              }
            }}
            clickEneabled={currentLocation === 'main' && me.type === 'team'}
          >
            <img
              src={team?.image ? team?.image : DefaultAvatar}
              alt={team?.name || 'Avatar'}
            />
          </ImageWrapper>
          <TeamInformationsHeader>
            <h5>{team?.name}</h5>
          </TeamInformationsHeader>
          {/* {currentLocation === 'main' && mobileChangeStatusUserButton} */}
        </div>
      </Header>
      <Content>
        <Switch>
          <Route exact path={routeMatch.path}>
            <Details team={team} setTeam={setTeam} routeMatch={routeMatch} />
          </Route>
          <Route path={`${routeMatch.path}/update_avatar`}>
            <UpdateAvatar
              team={team}
              setTeam={setTeam}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route exact path={`${routeMatch.path}/new_player`}>
            <NewPlayer team={team} getTeam={getTeam} />
          </Route>
          <Route exact path={`${routeMatch.path}/edit_team_data`}>
            <EditTeamData
              team={team}
              setTeam={setTeam}
              parentRouteMatch={routeMatch}
            />
          </Route>
          {/* <Route exact path={`${routeMatch.path}/cards/new`}>
            <NewMatchCardGame routeMatch={routeMatch} />
          </Route> */}
        </Switch>
      </Content>
      <Modal
        title="Avatar do time"
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        // cancelButtonProps={{
        //   disabled: loadingRemoveUserAvatar,
        // }}
        cancelText="Voltar"
      >
        <Link
          to={`${routeMatch.url}/update_avatar`}
          onClick={() => setAvatarModalVisible(false)}
        >
          <ActionButtonAvatar>
            <MdEdit size={24} />
            <span>Atualizar avatar</span>
          </ActionButtonAvatar>
        </Link>
      </Modal>
    </Container>
  );
}

export default Team;
