import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Container, TitlePageContainer, Content } from './styles';

import Steps from '~/components/Steps';
import Breadcrumbs from '~/components/Breadcrumbs';

import api from '~/services/api';

import CardInformations from './CardInformations';
import Athletes from './Athletes';
import Banner from './Banner';
import Confirm from './Confirm';

const { confirm } = Modal;

function New() {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [focusedField, setFocusedField] = useState(null);

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar o cadastro dessa cartela?',
      content: 'Todos os dados inseridos serão perdidos',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  function handleBeforeUnload(e) {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  const handleToogleCurrentStep = useCallback(newCurrentStep => {
    if (newCurrentStep < 0) {
      newCurrentStep = 0;
    }

    setCurrentStep(newCurrentStep);
  }, []);

  async function handleSubmit(values, actions) {
    await new Promise(resolve => {
      confirm({
        title: 'Deseja realmente criar essa cartela?',
        icon: <ExclamationCircleOutlined />,
        content:
          'As informações adicionadas não poderão ser alteradas posteriormente',
        cancelText: 'Cancelar',
        okText: 'Criar cartela',
        onOk() {
          resolve(true);
        },
        onCancel() {
          actions.setSubmitting(false);
        },
      });
    });

    const { cardInformations, athletes, banner } = values;

    let photoId = null;
    if (banner.file) {
      const formData = new FormData();
      formData.append('file', banner.file);
      formData.append('from', 'athletesCardGameAvatar');

      try {
        const { data } = await api.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        photoId = data._id;
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao enviar o banner da cartela!'
        );

        return;
      }
    }

    const body = {
      name: cardInformations.name,
      price: cardInformations.price,
      startDate: cardInformations.startDate.toDate(),
      endDate: cardInformations.endDate.toDate(),
      athletes: athletes.map(athlete => ({
        _team: athlete.team._id,
        _athlete: athlete._id,
      })),
      banner: photoId,
      finished: false,
    };

    try {
      await api.post('/api/athlete-card', body);

      toast.success('Cartela criada com sucesso!');

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();

      history.push(`/athletes_of_the_week`);
    } catch (error) {
      toast.error('Aconteceu um erro inesperado ao cadastrar a cartela!');
    }
  }

  return (
    <Container>
      <Breadcrumbs match={routeMatch} />
      <TitlePageContainer>
        <h4>Nova cartela</h4>
        <Button
          onClick={() => history.push(`/athletes_of_the_week`)}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Informações da cartela' },
            { title: 'Jogadores' },
            { title: 'Banner' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          initialValues={{
            cardInformations: {
              name: '',
              price: '',
              startDate: '',
              endDate: '',
            },
            athletes: [],
            banner: {
              file: null,
              previewUrl: '',
            },
          }}
          validationSchema={Yup.object().shape({
            cardInformations: Yup.object().shape({
              name: Yup.string().required('O nome da cartela é obrigatório'),
              price: Yup.number().required(
                'O valor do jogo na cartela é obrigatório'
              ),
              startDate: Yup.mixed().required(
                'A data inicial da cartela é obrigatória'
              ),
              endDate: Yup.mixed().required(
                'A data final da cartela é obrigatória'
              ),
            }),
            athletes: Yup.array()
              .of(
                Yup.object().shape({
                  _id: Yup.string().required(
                    'O identificador do jogo é obrigatório'
                  ),
                })
              )
              .min(1, 'Ao menos 1 jogo deve ser adicionado na cartela'),
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <CardInformations
                  formik={formikProps}
                  nextStep={() => handleToogleCurrentStep(1)}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 1 && (
                <Athletes
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(0)}
                  nextStep={() => handleToogleCurrentStep(2)}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 2 && (
                <Banner
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(1)}
                  nextStep={() => handleToogleCurrentStep(3)}
                />
              )}
              {currentStep === 3 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(2)}
                  onSubmit={formikProps.handleSubmit}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default New;
