/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useMemo } from 'react';
import { FiPlayCircle, FiPauseCircle } from 'react-icons/fi';
import * as dateFns from 'date-fns';

import useAudioPlayer from '../../../hooks/useAudioPlayer';

import MessageAudioBar from './MessageAudioBar';

import Env from '../../../config/Environment';
import DefaultAvatar from '../../../assets/DefaultAvatar.svg';

import {
  Container,
  UserName,
  HourAndMessageStatusContainer,
  SelectedForReportMessageGroupContainer,
  MessageAudioPlayerContainer,
  PlayOrStopButton,
} from './styles';

const { parseISO, format } = dateFns;

const Message = ({ message }) => {
  const audioRef = useRef(null);

  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
  } = useAudioPlayer({
    audioElement: audioRef.current,
  });

  const messageContentViewer = useMemo(() => {
    if (message.text) {
      return <p>{message.text}</p>;
    }

    if (message.voiceMsg) {
      return (
        <>
          <audio
            preload="none"
            loop={false}
            ref={audioRef}
            style={{ display: 'none' }}
            src={Env.IMAGE_SERVER_URL + message.voiceMsg._id}
          />
          <MessageAudioPlayerContainer>
            <PlayOrStopButton
              onClick={e => {
                e.stopPropagation();
                setPlaying(oldState => {
                  return !oldState;
                });
              }}
            >
              {!playing ? (
                <FiPlayCircle size={21} />
              ) : (
                <FiPauseCircle size={21} />
              )}
            </PlayOrStopButton>
            <MessageAudioBar
              duration={duration}
              curTime={curTime}
              onTimeUpdate={time => {
                setClickedTime(time);
              }}
            />
          </MessageAudioPlayerContainer>
        </>
      );
    }

    return <></>;
  }, [
    curTime,
    duration,
    message.text,
    message.voiceMsg,
    playing,
    setClickedTime,
    setPlaying,
  ]);

  return (
    <SelectedForReportMessageGroupContainer>
      <img
        src={
          message._user.photo
            ? Env.IMAGE_SERVER_URL + message._user.photo
            : DefaultAvatar
        }
        alt={message._user.name}
      />
      <div>
        <Container key={message._id}>
          <UserName>{message._user.name}</UserName>
          {messageContentViewer}
          <HourAndMessageStatusContainer>
            <small>{format(parseISO(message.createdAt), 'HH:mm')}</small>
          </HourAndMessageStatusContainer>
        </Container>
      </div>
    </SelectedForReportMessageGroupContainer>
  );
};

export default Message;
