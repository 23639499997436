import React, { useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { useRouteMatch, Link } from 'react-router-dom';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { toast } from 'react-toastify';

import {
  Container,
  TitlePageContainer,
  MatchCardsListContainer,
  MatchCardsList,
  MatchCardDetailContainer,
  MatchCardDetailMainContent,
  MatchCardDetailMatchsContent,
  MatchCardMatchItem,
  MatchCardMatchItemTeam,
  MatchCardMatchItemInfoContainer,
  LoadingMatchCardDetailsContainer,
} from './styles';

import api from '~/services/api';
import Env from '~/config/Environment';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { format, parseISO } = dateFns;

const cardTypesLabels = {
  new: 'Disponível para jogar',
  running: 'Em execução',
  finished: 'Finalizada',
};

function MatchCardGames() {
  const { params, url } = useRouteMatch();

  const [matchCards, setMatchCards] = useState([]);
  const [loadingMatchCards, setLoadingMatchCards] = useState(true);
  const [matchCardsPagination, setMatchCardsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    limit: 10,
  });

  const getMatchCardsOfChampionship = useCallback(
    async (page = 1) => {
      setLoadingMatchCards(true);
      try {
        const { data } = await api.get(`/api/card-game`, {
          params: {
            champ: params.id,
            page,
            limit: matchCardsPagination.limit,
          },
        });

        setMatchCards(
          data.docs.map(cardGame => ({
            ...cardGame,
            collapsedOnList: false,
            loadingDetails: false,
            loadedDetails: false,
          }))
        );
        setMatchCardsPagination(oldState => ({
          currentPage: data.page,
          totalPages: data.total,
          limit: oldState.limit,
        }));
        setLoadingMatchCards(false);
      } catch (error) {
        setLoadingMatchCards(false);
      }
      return '';
    },
    [matchCardsPagination.limit, params.id]
  );

  const getMatchCardDetails = useCallback(async _matchCard => {
    setMatchCards(oldMatchCards =>
      oldMatchCards.map(oldMatchCard => {
        if (oldMatchCard._id === _matchCard) {
          return {
            ...oldMatchCard,
            collapsedOnList: true,
            loadingDetails: true,
          };
        }
        return { ...oldMatchCard, collapsedOnList: false };
      })
    );

    // setMatchCards(oldMatchCards => {
    //   openedMatchCardIndex = oldMatchCards.findIndex(
    //     matchCard => matchCard._id === _matchCard
    //   );
    //   const updatedMatchCards = [...oldMatchCards];
    //   updatedMatchCards[openedMatchCardIndex].collapsedOnList = true;
    //   updatedMatchCards[openedMatchCardIndex].loadingDetails = true;
    //   return updatedMatchCards;
    // });
    try {
      const { data } = await api.get(`/api/card-game/${_matchCard}`);

      setMatchCards(oldMatchCards =>
        oldMatchCards.map(oldMatchCard => {
          if (oldMatchCard._id === _matchCard) {
            return {
              ...oldMatchCard,
              games: data.games,
              gameCount: data.gameCount,
              amount: data.amount,
              loadingDetails: false,
              loadedDetails: true,
            };
          }
          return { ...oldMatchCard };
        })
      );

      // setMatchCards(oldMatchCards => {
      //   const updatedMatchCards = [...oldMatchCards];
      //   updatedMatchCards[openedMatchCardIndex].games = data.games;
      //   updatedMatchCards[openedMatchCardIndex].gameCount = data.gameCount;
      //   updatedMatchCards[openedMatchCardIndex].amount = data.amount;
      //   updatedMatchCards[openedMatchCardIndex].loadingDetails = false;
      //   updatedMatchCards[openedMatchCardIndex].loadedDetails = true;
      //   return updatedMatchCards;
      // });
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da cartela. Recarregue a página e tente novamente!'
      );
    }
    return '';
  }, []);

  useEffect(() => {
    getMatchCardsOfChampionship();
  }, [getMatchCardsOfChampionship]);

  // console.log(loadingMatchCards);

  if (loadingMatchCards) {
    return (
      <Container>
        <TitlePageContainer>
          <h4>Cartelas</h4>
          <div>
            <Link to={`/championships/${params.id}`}>
              <Button type="text">Voltar</Button>
            </Link>
            <Link to={`${url}/new`}>
              <Button type="primary">Nova cartela</Button>
            </Link>
          </div>
        </TitlePageContainer>
        <MatchCardsListContainer>
          <Spin indicator={antIcon} />
        </MatchCardsListContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TitlePageContainer>
        <h4>Cartelas</h4>
        <div>
          <Link to={`/championships/${params.id}`}>
            <Button type="text">Voltar</Button>
          </Link>
          <Link to={`${url}/new`}>
            <Button type="primary">Nova cartela</Button>
          </Link>
        </div>
      </TitlePageContainer>
      <MatchCardsListContainer>
        <MatchCardsList>
          {matchCards.map(matchCard => (
            <MatchCardDetailContainer
              onClick={() => {
                if (!matchCard.loadingDetails && !matchCard.loadedDetails) {
                  getMatchCardDetails(matchCard._id);
                  return;
                }
                if (matchCard.loadedDetails) {
                  setMatchCards(oldMatchCards =>
                    oldMatchCards.map(oldMatchCard => {
                      if (oldMatchCard._id === matchCard._id) {
                        return {
                          ...oldMatchCard,
                          collapsedOnList: !oldMatchCard.collapsedOnList,
                        };
                      }
                      return { ...oldMatchCard, collapsedOnList: false };
                    })
                  );
                }
              }}
              key={matchCard._id}
            >
              <MatchCardDetailMainContent
                bg={
                  matchCard.banner?.filename &&
                  Env.IMAGE_SERVER_URL + matchCard.banner?.filename
                }
                cardType={matchCard.cardType}
                collapsedOnList={matchCard.collapsedOnList}
              >
                <div>
                  <h6>{matchCard.name}</h6>
                  {!matchCard.collapsedOnList ? (
                    <FiChevronDown size={24} />
                  ) : (
                    <FiChevronUp size={24} />
                  )}
                </div>
                <small>{cardTypesLabels[matchCard.cardType]}</small>
                <small>
                  <b>Valor do jogo: </b>LF$ {matchCard.price}
                </small>
                <small>
                  <b>Data de início: </b>
                  {format(
                    parseISO(matchCard.startDate),
                    "cccc, dd/MM/yyyy 'às' HH:mm",
                    {
                      locale: ptLocale,
                    }
                  )}
                </small>
              </MatchCardDetailMainContent>
              {matchCard.collapsedOnList && (
                <>
                  {matchCard.loadingDetails ? (
                    <MatchCardDetailMatchsContent>
                      <LoadingMatchCardDetailsContainer>
                        <Spin indicator={antIcon} />
                      </LoadingMatchCardDetailsContainer>
                    </MatchCardDetailMatchsContent>
                  ) : (
                    <MatchCardDetailMatchsContent>
                      {matchCard.games ? (
                        matchCard.games.map(({ game: match }) => (
                          <MatchCardMatchItem key={match._id}>
                            <div>
                              <MatchCardMatchItemTeam>
                                <div>
                                  <img
                                    src={match.local.image}
                                    alt={match.local.name}
                                  />
                                </div>
                                <strong>{match.local.name}</strong>
                              </MatchCardMatchItemTeam>
                              <h6>VS</h6>
                              <MatchCardMatchItemTeam>
                                <div>
                                  <img
                                    src={match.away.image}
                                    alt={match.away.name}
                                  />
                                </div>
                                <strong>{match.away.name}</strong>
                              </MatchCardMatchItemTeam>
                            </div>
                            <MatchCardMatchItemInfoContainer>
                              <div>
                                <small>Data e hora</small>
                                <small>
                                  {format(
                                    parseISO(match.info.date),
                                    "cccc, dd/MM/yyyy 'às' HH:mm",
                                    {
                                      locale: ptLocale,
                                    }
                                  )}
                                </small>
                              </div>
                              <div>
                                <small>Local</small>
                                <small>{match.info.place}</small>
                              </div>
                            </MatchCardMatchItemInfoContainer>
                          </MatchCardMatchItem>
                        ))
                      ) : (
                        <p>Nenhum jogo encontrado nessa cartela</p>
                      )}
                    </MatchCardDetailMatchsContent>
                  )}
                </>
              )}
            </MatchCardDetailContainer>
          ))}
        </MatchCardsList>
        <Pagination
          current={matchCardsPagination.currentPage}
          onChange={page => {
            setMatchCardsPagination(oldState => ({
              ...oldState,
              currentPage: page,
            }));
            getMatchCardsOfChampionship(page);
          }}
          total={matchCardsPagination.totalPages}
          pageSize={matchCardsPagination.limit}
        />
      </MatchCardsListContainer>
    </Container>
  );
}

export default MatchCardGames;
