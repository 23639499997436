/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Radio as RadioAnt } from 'antd';

import { Container } from './styles';

function Radio({ label, items, error, ...rest }) {
  return (
    <Container error={error}>
      {label && <label>{label}</label>}
      <RadioAnt.Group {...rest}>
        {items.map(item => (
          <RadioAnt key={item.value} value={item.value}>
            {item.label}
          </RadioAnt>
        ))}
      </RadioAnt.Group>
      {error && <span>{error}</span>}
    </Container>
  );
}

export default Radio;
