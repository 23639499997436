import styled, { css } from 'styled-components';

const getCardTypeColor = styleProps => ({
  new: styleProps.theme.colors.primary.primary0,
  running: '#e4ce00',
  finished: styleProps.theme.colors.danger,
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 32px; */
  margin-bottom: 32px;
  margin-top: 32px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }

  /* AntD Button Changes */
  button {
    margin-left: 16px;
  }
`;

export const Content = styled.div`
  padding: 0 15%;
  display: flex;
  flex-direction: column;

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 728px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;

    form {
      width: 100%;
      min-width: 100%;
      margin-top: 16px;
    }
  }
`;

export const AthleteCardDetailContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.light.light0};
  border: 0;
  /* padding: 16px; */
  border-radius: 8px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const AthleteCardDetailMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  ${props =>
    props.bg &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.8)
        ),
        url(${props.bg}) center no-repeat;
      background-size: cover;
    `}

  > div {
    width: 100%;
    display: flex;
    margin-bottom: 4px;

    h6 {
      text-align: start;
      flex: 1;
      line-height: 110%;
    }

    svg {
      color: ${props => props.theme.colors.dark['dark+4']};
    }
  }

  small {
    color: ${props => props.theme.colors.dark['dark+1']};

    &:nth-child(2) {
      color: ${props => getCardTypeColor(props)[props.cardType]};
      margin-bottom: 16px;
    }
  }
`;

export const AthleteCardDetailPlayersContent = styled.div`
  /* margin-top: 16px; */
  /* padding-top: 16px; */
  padding: 16px;
  border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;

  > a {
    margin-bottom: 8px;
  }

  > button {
    width: 100%;
  }
`;

export const AthleteCardPlayer = styled.div`
  border: 0;
  background: ${props => props.theme.colors.light.light0};
  width: 100%;
  padding: 16px 0;
  text-align: start;
  display: flex;
  flex-direction: column;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  }

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    ${props =>
      !props.$status &&
      css`
        opacity: 0.7;
      `}

    > div:nth-child(1) {
      width: 72px;
      height: 72px;
      border-radius: 8px;
      border: 1px solid ${props => props.theme.colors.dark['dark+4']};
      background: ${props => props.theme.colors.light['light-2']};
      transition: 0.2s opacity;
      margin-right: 16px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    div:nth-child(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      small {
        overflow: inherit;
        text-overflow: inherit;
        color: ${props => props.theme.colors.dark['dark+2']};
      }

      div {
        margin-top: 8px;
        display: flex;
        align-items: center;

        img {
          height: 18px;
          margin-right: 8px;
        }

        p {
          color: ${props => props.theme.colors.dark['dark+2']};
        }
      }
    }

    > h6 {
      flex-shrink: 0;
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const QuantityOfPlayerGoalsSelectorContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin-left: auto;

  h6 {
    color: ${props => props.theme.colors.dark.dark0};
    margin: 0 16px;
  }
`;

export const AthleteCardFinishedSwithContainer = styled.div`
  width: 100%;
  padding-top: 16px;
  display: flex;
  align-items: flex-start;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 16px;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto 0;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.dark['dark+3']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.dark['dark+3']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
