import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useParams,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Container,
  Header,
  Content,
  ImageWrapper,
  ChampionshipInformationsHeader,
  ChampionshipNotFoundContainer,
} from './styles';

import Breadcrums from '~/components/Breadcrumbs';
import CustomAntButton from '~/components/CustomAntButton';

import Env from '~/config/Environment';
import api from '~/services/api';

import NotFoundImage from '~/assets/NotFound.svg';
import DefaultAvatar from '~/assets/DefaultAvatar.svg';

import Details from './Details';
import EditChampionshipInformations from './EditChampionshipInformations';
import UpdateBanner from './UpdateBanner';
import MatchCardGames from './MatchCardGames';
import NewMatchCardGame from './MatchCardGames/New';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Championship({ match }) {
  const params = useParams();
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const [championship, setChampionship] = useState(null);
  const [loadingChampionship, setLoadingChampionship] = useState(true);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  const getChampionship = useCallback(async _id => {
    if (!_id) {
      return console.log('Erro!!');
    }

    try {
      const { data } = await api.get(`/api/championship/${_id}`);

      setChampionship(data);
      setLoadingChampionship(false);
    } catch (error) {
      setLoadingChampionship(false);
    }
    return '';
  }, []);

  useEffect(() => {
    getChampionship(params?.id);
  }, [getChampionship, params.id]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_banner')) {
      currentLocationName = 'updateBanner';
    } else if (page.includes('/edit_championship_informations')) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  if (loadingChampionship) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  if (!championship) {
    return (
      <Container>
        <ChampionshipNotFoundContainer>
          <Breadcrums match={routeMatch} />
          <div>
            <img src={NotFoundImage} alt="Not Found" />
            <h6>Esse campeonato não foi encontrado</h6>
          </div>
        </ChampionshipNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header
        $bg={
          championship.background &&
          Env.IMAGE_SERVER_URL + championship.background
        }
      >
        <Breadcrums match={match} />
        <div>
          <ImageWrapper
          // onClick={() => {
          //   if (currentLocation === 'main') {
          //     setAvatarModalVisible(true);
          //   }
          // }}
          // clickEneabled={currentLocation === 'main'}
          >
            <img
              src={
                championship?.imageUrl ? championship?.imageUrl : DefaultAvatar
              }
              alt={championship?.name || 'Avatar'}
            />
          </ImageWrapper>
          <ChampionshipInformationsHeader>
            <h5>{championship?.name}</h5>
            {currentLocation === 'main' && (
              <Link to={`${routeMatch.url}/update_banner`}>
                <CustomAntButton type="text" textprimary>
                  Atualizar banner
                </CustomAntButton>
              </Link>
            )}
          </ChampionshipInformationsHeader>
          {/* {currentLocation === 'main' && mobileChangeStatusUserButton} */}
        </div>
      </Header>
      <Content>
        <Switch>
          <Route exact path={routeMatch.path}>
            <Details championship={championship} routeMatch={routeMatch} />
          </Route>
          <Route
            exact
            path={`${routeMatch.path}/edit_championship_informations`}
          >
            <EditChampionshipInformations
              championship={championship}
              setChampionship={setChampionship}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route exact path={`${routeMatch.path}/cards`}>
            <MatchCardGames routeMatch={routeMatch} />
          </Route>
          <Route exact path={`${routeMatch.path}/cards/new`}>
            <NewMatchCardGame routeMatch={routeMatch} />
          </Route>
          <Route path={`${routeMatch.path}/update_banner`}>
            <UpdateBanner
              championship={championship}
              setChampionship={setChampionship}
            />
          </Route>
          {/* <Route path={`${routeMatch.path}/edit_user_informations`}>
            <EditUserInformations
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_personal_data`}>
            <EditPersonalData
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route> */}
        </Switch>
      </Content>
      <Modal
        title="Avatar do usuário"
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        // cancelButtonProps={{
        //   disabled: loadingRemoveUserAvatar,
        // }}
        cancelText="Voltar"
      >
        {/* {contentModal} */}
      </Modal>
    </Container>
  );
}

export default Championship;
