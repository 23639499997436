import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import MobileHeader from '~/components/MobileHeader';
import Sidebar from '~/components/Sidebar';
import LoadingUserData from '~/components/LoadingUserData';

import history from '~/services/history';
import { Wrapper, ContentPage } from './styles';

export default function DefaultLayout({ children }) {
  const loadingUser = useSelector(state => state.user.loading);
  const loadingTeam = useSelector(state => state.team.loading);

  const [mobileSidebarOpened, setMobileSidebarOpened] = useState(false);

  const handleToogleSidebarState = useCallback(() => {
    setMobileSidebarOpened(!mobileSidebarOpened);
  }, [mobileSidebarOpened]);

  if (loadingUser || loadingTeam) {
    return <LoadingUserData />;
  }

  return (
    <Wrapper>
      <MobileHeader
        mobileSidebarOpened={mobileSidebarOpened}
        handleToogleSidebarState={handleToogleSidebarState}
      />
      <Sidebar
        opened={mobileSidebarOpened}
        handleToogleSidebarState={handleToogleSidebarState}
        history={history}
      />
      <ContentPage>{children}</ContentPage>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
