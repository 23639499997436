import React, { useState, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import api from '~/services/api';
import socket from '~/services/socket';

import PushGameList from './PushGameList';
import NewPushGame from './NewPushGame';

// import Throws from './Throws';
// import Statistics from './Statistics';

import { Container, LoadingAndNotFoundContainer } from './styles';

const loadingIcon = <LoadingOutlined style={{ fontSize: 21 }} spin />;

function PushGame({ selectedMatchDetails }) {
  const [addingNewGame, setAddingNewGame] = useState(false);

  const [pushGames, setPushGames] = useState([]);
  const [pushGamesPagination, setPushGamesPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });
  const [loadingPushGames, setLoadingPushGames] = useState(true);

  const getPushGames = useCallback(
    async (page = 1) => {
      setLoadingPushGames(true);
      if (page === 1) {
        setPushGames([]);
      }

      try {
        // const data = {
        //   docs: [],
        //   page: 1,
        //   pages: 0,
        // };
        const { data } = await api.get('/api/push-game', {
          params: {
            page,
            game: selectedMatchDetails._id,
            limit: pushGamesPagination.limit,
          },
        });

        if (page === 1) {
          setPushGames(data.docs);
        } else {
          setPushGames(oldState => [...oldState, ...data.docs]);
        }

        setLoadingPushGames(false);
        setPushGamesPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao carregar os jogos, recarregue a página!'
        );
        setLoadingPushGames(false);
      }
    },
    [pushGamesPagination.limit, selectedMatchDetails._id]
  );

  useEffect(() => {
    getPushGames();
  }, [getPushGames]);

  useEffect(() => {
    socket.on(`push-game-list`, async data => {
      if (data === 'reload') {
        getPushGames();
      }
    });
    return () => {
      socket.off(`push-game-list`);
    };
  }, [getPushGames]);

  if (loadingPushGames && pushGames.length === 0) {
    return (
      <Container>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>Carregando jogos...</p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      {!addingNewGame ? (
        <PushGameList
          setAddingNewGame={setAddingNewGame}
          pushGames={pushGames}
          pushGamesPagination={pushGamesPagination}
          loadingPushGames={loadingPushGames}
          getPushGames={getPushGames}
        />
      ) : (
        <NewPushGame
          setAddingNewGame={setAddingNewGame}
          matchId={selectedMatchDetails._id}
          getPushGames={getPushGames}
        />
      )}
    </Container>
  );
}

export default PushGame;
