import React from 'react';
import { Button } from 'antd';
import { FastField } from 'formik';

import Input from '~/components/Input';
import DatePicker from '~/components/DatePicker';

import { ButtonsContainer } from '../styles';

function disabledDateTime() {
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  return {
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    disabledSeconds: () => range(0, 60),
  };
}

function CardInformations({ formik, nextStep, setFocusedField }) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.cardInformations) {
      return false;
    }

    if (!errors.cardInformations) {
      return true;
    }

    return false;
  }

  return (
    <>
      <FastField name="cardInformations.name">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome da cartela"
              placeholder="Digite o nome da cartela"
              identifier="matchCardName"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('cardInformations.name')}
            />
          );
        }}
      </FastField>
      <FastField name="cardInformations.price">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Valor do jogo"
              placeholder="Digite o valor do jogo da cartela"
              identifier="matchCardPrice"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('cardInformations.price')}
              addonBefore="LF$"
            />
          );
        }}
      </FastField>
      <FastField name="cardInformations.startDate">
        {({ field, meta }) => {
          return (
            <DatePicker
              {...field}
              label="Data e hora inicial"
              identifier="startDate"
              error={meta?.touched && meta?.error}
              onChange={momentDate => {
                if (momentDate) {
                  momentDate.set('second', 0);
                }
                formik.setFieldValue('cardInformations.startDate', momentDate);
              }}
              showTime
              disabledTime={disabledDateTime}
              format="dddd, DD/MM/YYYY [às] HH:mm"
            />
          );
        }}
      </FastField>
      <FastField name="cardInformations.endDate">
        {({ field, meta }) => {
          return (
            <DatePicker
              {...field}
              label="Data e hora final"
              identifier="endDate"
              error={meta?.touched && meta?.error}
              onChange={momentDate => {
                if (momentDate) {
                  momentDate.set('second', 0);
                }
                formik.setFieldValue('cardInformations.endDate', momentDate);
              }}
              showTime
              disabledTime={disabledDateTime}
              format="dddd, DD/MM/YYYY [às] HH:mm"
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default CardInformations;
